import React, { useState, useEffect } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonList,
  IonLabel,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonButton,
  useIonViewDidEnter,
  // IonListHeader,
  IonIcon,
  getConfig,
} from '@ionic/react';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import {
  // setTextSize,
  setThemeSetting,
} from '../../data/user/user.actions';
import { connect } from '../../data/connect';
// import './Preferences.scss';
import { cogOutline, moon, sunny } from 'ionicons/icons';

const DisplayTheme = ({
  // textSize,
  // setTextSize,
  themeSetting,
  setThemeSetting,
  onDismissModal,
  modalRef,
  darkMode,
  mode,
}) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Display & Theme',
      nameOverride: 'Display & Theme',
    });
  });

  function dismiss() {
    modalRef.current.dismiss();
  }

  // const [textSelected, setTextSelected] = useState();

  // useEffect(() => {
  //   setTextSelected(textSize);
  // }, [textSize]);
  // const handleTextChange = value => {
  //   setTextSelected(value);
  //   if (value === 'small') {
  //     setTextSize('small');
  //     return;
  //   }
  //   if (value === 'medium') {
  //     setTextSize('medium');
  //     return;
  //   }
  //   if (value === 'large') {
  //     setTextSize('large');
  //     return;
  //   }
  //   if (value === 'xlarge') {
  //     setTextSize('xlarge');
  //     return;
  //   }
  // };

  const [themeSelected, setThemeSelected] = useState();
  useEffect(() => {
    setThemeSelected(themeSetting);
  }, [themeSetting]);
  const handleThemeChange = value => {
    setThemeSelected(value);
    setThemeSetting(value);
  };

  return (
    <IonPage id="preferences-page">
      <IonHeader className="modal-header ion-no-border">
        <IonToolbar className="main-menu">
          <IonTitle>App Theme</IonTitle>
          <IonButtons slot={mode === 'ios' ? 'start' : 'end'}>
            <IonButton onClick={() => dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* {isPlatform('capacitor') && ( */}
        {/* <>
          <IonList className="radio-list">
            <IonListHeader style={{ paddingBottom: '7px', marginTop: 'px' }}>
              Text Size
            </IonListHeader>
            <IonRadioGroup
              value={textSelected}
              onIonChange={e => handleTextChange(e.detail.value)}
            >
              <IonItem>
                <IonIcon
                  slot="start"
                  color="dark"
                  icon={text}
                  style={{ fontSize: '18px', minWidth: '28px' }}
                />
                <IonLabel>Small</IonLabel>
                <IonRadio slot="end" value="small" />
              </IonItem>
              <IonItem>
                <IonIcon
                  slot="start"
                  color="dark"
                  icon={text}
                  style={{ fontSize: '20px', minWidth: '28px' }}
                />
                <IonLabel>Medium (default)</IonLabel>
                <IonRadio slot="end" value="medium" />
              </IonItem>
              <IonItem>
                <IonIcon
                  slot="start"
                  color="dark"
                  icon={text}
                  style={{ fontSize: '22px', minWidth: '28px' }}
                />
                <IonLabel>Large</IonLabel>
                <IonRadio slot="end" value="large" />
              </IonItem>
              <IonItem>
                <IonIcon
                  slot="start"
                  color="dark"
                  icon={text}
                  style={{ fontSize: '25px', minWidth: '28px' }}
                />
                <IonLabel>Extra Large</IonLabel>
                <IonRadio slot="end" value="xlarge" />
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </> */}
        {/* )} */}
        <IonList className="radio-list" style={{ marginTop: '0px' }}>
          {/* <IonListHeader style={{ paddingBottom: '7px', paddingTop: '10px' }}>
            App Theme
          </IonListHeader> */}
          <IonRadioGroup
            value={themeSelected}
            onIonChange={e => handleThemeChange(e.detail.value)}
          >
            <IonItem>
              <IonIcon
                slot="start"
                color={darkMode ? 'primary' : 'warning'}
                icon={sunny}
              />
              <IonLabel>Light Theme</IonLabel>
              <IonRadio slot="end" value="light" />
            </IonItem>
            <IonItem>
              <IonIcon
                slot="start"
                color={darkMode ? 'warning' : 'primary'}
                icon={moon}
              />
              <IonLabel>Dark Theme</IonLabel>
              <IonRadio slot="end" value="dark" />
            </IonItem>
            <IonItem>
              <IonIcon slot="start" color="" icon={cogOutline} />
              <IonLabel>
                Use device settings
                {/* <p>
                  Set App Theme to use the Light or Dark selection located in
                  your device's display settings.
                </p> */}
              </IonLabel>
              <IonRadio slot="end" value="device" />
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: state => ({
    darkMode: state.user.darkMode,
    themeSetting: state.user.themeSetting,
    // textSize: state.user.textSize,
    mode: getConfig().get('mode'),
  }),
  mapDispatchToProps: {
    setThemeSetting,
    // setTextSize,
  },
  component: React.memo(DisplayTheme),
});
