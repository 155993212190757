import React, { useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonList,
  IonLabel,
  IonItem,
  IonButton,
  useIonViewDidEnter,
  IonInput,
  useIonToast,
  IonTextarea,
  IonSpinner,
  getConfig,
} from '@ionic/react';
import { withRouter } from 'react-router';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { hapticsImpact } from '../../services/hapticsApi';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { connect } from '../../data/connect';
import axios from 'axios';
import * as Yup from 'yup';
import './Preferences.scss';

const ContactForm = ({ project, modalRef, mode }) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'App Info',
      nameOverride: 'App Info',
    });
  });

  function dismissModal() {
    modalRef.current.dismiss();
  }

  const [isLoading, setIsLoading] = useState(false);
  const [present, dismiss] = useIonToast();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('* Name is required'),
    email: Yup.string().email().required('* Email is required'),
    message: Yup.string().required('* Message is required'),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      project: project.projname,
      projectEmail: project.pocemail,
      name: '',
      email: '',
      message: '',
    },
  });
  async function handleSend(data) {
    setIsLoading(true);
    axios
      .post(
        'https://us-central1-tampa-cip.cloudfunctions.net/contactMessage',
        data,
      )
      .then(function (response) {
        hapticsImpact('medium');
        if (response.status === 200) {
          present({
            duration: 2000,
            buttons: [{ text: 'Hide', handler: () => dismiss() }],
            animated: true,
            position: 'bottom',
            color: 'dark',
            cssClass: 'bottom-toast',
            message: 'Message sent successfully',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dismissModal();

        setIsLoading(false);
        reset(
          {
            name: '',
            email: '',
            message: '',
          },
          {
            keepDefaultValues: true,
            keepIsValid: true,
          },
        );
      });
  }

  return (
    <>
      <IonHeader className="modal-header ion-no-border">
        <IonToolbar>
          <IonTitle>Send A Message</IonTitle>
          <IonButtons slot={mode === 'ios' ? 'start' : 'end'}>
            <IonButton onClick={() => dismissModal()}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form className="contactForm">
          <IonList color="white">
            <IonItem hidden>
              <IonInput
                name="project"
                value={project.projname}
                placeholder={undefined}
              ></IonInput>
            </IonItem>

            <IonItem hidden>
              <IonInput
                name="projectEmail"
                value={project.pocemail}
                placeholder={undefined}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Name</IonLabel>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <IonInput
                    inputMode="text"
                    value={value}
                    onIonBlur={onBlur}
                    onIonChange={e => onChange(e.detail.value)}
                  />
                )}
              />
            </IonItem>
            {errors.name && (
              <p
                style={{
                  fontSize: '12px',
                  marginLeft: '16px',
                  marginTop: '7px',
                  color: 'var(--ion-color-danger)',
                }}
              >
                {errors.name.message}
              </p>
            )}
            <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <IonInput
                    type="email"
                    value={value}
                    onIonBlur={onBlur}
                    onIonChange={e => onChange(e.detail.value)}
                    autocomplete
                    placeholder={undefined}
                  />
                )}
              />
            </IonItem>
            {errors.email && (
              <p
                style={{
                  fontSize: '12px',
                  marginLeft: '16px',
                  marginTop: '7px',
                  color: 'var(--ion-color-danger)',
                }}
              >
                {errors.email.message}
              </p>
            )}
            <IonItem>
              <IonLabel position="stacked">Message</IonLabel>
              <Controller
                name="message"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <IonTextarea
                    rows="3"
                    placeholder={undefined}
                    inputMode="text"
                    value={value}
                    onIonBlur={onBlur}
                    onIonChange={e => onChange(e.detail.value)}
                  />
                )}
              />
            </IonItem>
            {errors.message && (
              <p
                style={{
                  fontSize: '12px',
                  marginLeft: '16px',
                  marginTop: '7px',
                  color: 'var(--ion-color-danger)',
                }}
              >
                {errors.message.message}
              </p>
            )}
            <div style={{ margin: '20px 16px 16px 16px' }}>
              <IonButton
                strong
                fill="solid"
                size="default"
                expand="block"
                color="primary"
                onClick={handleSubmit(data => handleSend(data))}
              >
                {isLoading ? (
                  <IonSpinner
                    slot="end"
                    name="lines-small"
                    className="cip-spinner"
                  />
                ) : (
                  <span style={{ marginRight: '7px' }}>Submit</span>
                )}
              </IonButton>
            </div>
          </IonList>
        </form>
      </IonContent>
    </>
  );
};

export default connect({
  mapStateToProps: state => ({
    darkMode: state.user.darkMode,
    mode: getConfig().get('mode'),
  }),
  component: withRouter(ContactForm),
});
