import { Network } from '@capacitor/network';
import { getConfig, IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { connect } from '../../data/connect';
import ArcGisMap from './ArcGisMap';
import './ExploreTab.scss';

const ExploreTabPortal = ({ mode }) => {
  const location = useLocation();

  const [network, setNetwork] = useState(null);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      if (status.connected) {
        setNetwork(true);
        // console.log('initial network status: ONLINE (load app data)');
      } else if (!status.connected) {
        setNetwork(false);
        // console.log('initial network status: OFFLINE (load app data)');
      }
    };
    checkNetworkStatus();

    Network.addListener('networkStatusChange', status => {
      if (status.connected) {
        setNetwork(true);

        // console.log('network status change: NOW ONLINE');
      } else if (!status.connected) {
        setNetwork(false);
        // console.log('network status change: NOW OFFLINE');
      }
    });
  }, []);

  return (
    <IonPage
      id="map-view"
      style={{
        height: `calc(100% - ${
          mode === 'ios' ? '50px' : '56px'
        } - var(--ion-safe-area-bottom, 0)`,
        opacity: `${location.pathname === '/tabs/explore' ? '1' : '0'}`,
        zIndex: `${location.pathname === '/tabs/explore' ? 'initial' : '-1'}`,
      }}
    >
      <IonContent
        class="map-page"
        fullscreen={true}
        slot="fixed"
        force-overscroll={false}
      >
        <ArcGisMap network={network} />
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: state => ({
    mode: getConfig().get('mode'),
  }),
  component: ExploreTabPortal,
});
