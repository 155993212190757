import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App as CapApp } from '@capacitor/app';

const DeepLinkListener = () => {
  let history = useHistory();
  useEffect(() => {
    CapApp.addListener('appUrlOpen', async data => {
      const slug = data.url.split('.app').pop();
      if (slug) {
        history.push(slug);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default DeepLinkListener;
