import { IonSkeletonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';

const TimeSince = ({ date, actionText }) => {
  const [timeSince, setTimeSince] = useState();
  //   useEffect(() => {
  //     const startTime = new Date(date);
  //     const endTime = new Date();
  //     const timeDiff = endTime - startTime;
  //     function convertMS(ms) {
  //       let d;
  //       let h;
  //       let m;
  //       let s;
  //       s = Math.floor(ms / 1000);
  //       m = Math.floor(s / 60);
  //       s %= 60;
  //       h = Math.floor(m / 60);
  //       m %= 60;
  //       d = Math.floor(h / 24);
  //       h %= 24;
  //
  //       return { d, h, m, s };
  //     }
  //     const timeSinceIssued = convertMS(timeDiff);
  //     const daysSince = timeSinceIssued.d.toString();
  //     const hoursSince = timeSinceIssued.h.toString();
  //     const minsSince = timeSinceIssued.m.toString();
  //     const secsSince = timeSinceIssued.s.toString();
  //     if (daysSince < 1 && hoursSince > 0) {
  //       setTimeSince(`${hoursSince}hr ago`);
  //     } else if (hoursSince < 1 && minsSince > 0) {
  //       setTimeSince(`${minsSince}m ago`);
  //     } else if (minsSince < 1) {
  //       setTimeSince(`${secsSince}s ago`);
  //     } else {
  //       setTimeSince(`${daysSince}d ago`);
  //     }
  //   }, [date]);
  useEffect(() => {
    setTimeSince(date);
  }, [date]);
  return (
    <span style={{ fontSize: '12.5px' }}>
      {timeSince ? (
        // `${actionText} ${timeSince}`
        <>
          {' '}
          {actionText} <ReactTimeAgo date={timeSince} locale="en-US" />
        </>
      ) : (
        <IonSkeletonText
          animated
          style={{ width: '100px', height: '13px', borderRadius: '3px' }}
        />
      )}
    </span>
  );
};

export default TimeSince;
