export function createParagraphs(text) {
  return {
    __html: text.replace(/\n/g, '<br />'),
  };
}

export const dateFormatter = timestamp => {
  var date = new Date(timestamp);
  var month = [];
  month[0] = 'January';
  month[1] = 'February';
  month[2] = 'March';
  month[3] = 'April';
  month[4] = 'May';
  month[5] = 'June';
  month[6] = 'July';
  month[7] = 'August';
  month[8] = 'September';
  month[9] = 'October';
  month[10] = 'November';
  month[11] = 'December';
  var m = month[date.getMonth()];
  var d = date.getDate();
  var year = date.getFullYear();

  return m + ` ` + d + `, ` + year;
};
export const updatedFormatter = timestamp => {
  var date = new Date(timestamp);
  var month = [];
  month[0] = 'January';
  month[1] = 'February';
  month[2] = 'March';
  month[3] = 'April';
  month[4] = 'May';
  month[5] = 'June';
  month[6] = 'July';
  month[7] = 'August';
  month[8] = 'September';
  month[9] = 'October';
  month[10] = 'November';
  month[11] = 'December';
  var m = month[date.getMonth()];
  var d = date.getDate();
  var hr = date.getHours();
  var min = date.getMinutes();
  if (min < 10) {
    min = '0' + min;
  }
  var ampm = 'am';
  if (hr > 12) {
    hr -= 12;
    ampm = 'pm';
  }
  return m + ` ` + d + ` at ` + hr + ':' + min + ampm;
};
export const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});
