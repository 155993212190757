import React from 'react';
import { getMode } from '@ionic/core/components';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonFooter,
  IonIcon,
  useIonViewDidEnter,
} from '@ionic/react';
import {
  checkmarkCircle,
  clipboard,
  construct,
  create,
  receipt,
} from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import {
  updateFilteredProjectCategories,
  updateFilteredProjectPhases,
} from '../../data/app-data/app-data.actions';
import { connect } from '../../data/connect';
import {
  faUsers,
  faTrashAlt,
  faHouseFlood,
  faSimCard,
  faCarBus,
  faToilet,
  faLandmark,
  faParking,
  faFaucetDrip,
  faCarBuilding,
  faBus,
  faFireSmoke,
  faCity,
  faHandHoldingUsd,
  faFileSignature,
  faGlobeAmericas,
  faTrees,
} from '@fortawesome/pro-solid-svg-icons';
import './Preferences.scss';

const ProjectFilter = ({
  projectCategories,
  filteredProjectCategories,
  projectPhases,
  filteredProjectPhases,
  modalRef,
  updateFilteredProjectCategories,
  updateFilteredProjectPhases,
}) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Projects Filter',
      nameOverride: 'Projects Filter',
    });
  });
  const ios = getMode() === 'ios';

  const toggleCategoryFilter = track => {
    if (filteredProjectCategories.indexOf(track) > -1) {
      updateFilteredProjectCategories(
        filteredProjectCategories.filter(x => x !== track),
      );
    } else {
      updateFilteredProjectCategories([...filteredProjectCategories, track]);
    }
  };

  const togglePhaseFilter = track => {
    if (filteredProjectPhases.indexOf(track) > -1) {
      updateFilteredProjectPhases(
        filteredProjectPhases.filter(x => x !== track),
      );
    } else {
      updateFilteredProjectPhases([...filteredProjectPhases, track]);
    }
  };

  const handleDeselectAll = () => {
    updateFilteredProjectCategories([]);
    updateFilteredProjectPhases([]);
  };

  const handleSelectAll = () => {
    updateFilteredProjectCategories([...projectCategories]);
    updateFilteredProjectPhases([...projectPhases]);
  };

  const iconMap = {
    'Art & Cultural Affairs': faGlobeAmericas,
    'Contract Administration': faFileSignature,
    'Convention Center & Tourism': faLandmark,
    'Economic Opportunity': faHandHoldingUsd,
    'Facility Management': faCity,
    'Fire': faFireSmoke,
    'Fleet Maintenance': faBus,
    'HR-Risk Management': faUsers,
    'Parking': faParking,
    'Parks & Recreation': faTrees,
    'Planning': 'Planning',
    'Police': 'Police',
    'Solid Waste & EPM': faTrashAlt,
    'Stormwater Engineering': faHouseFlood,
    'Technology & Innovation': faSimCard,
    'Transportation': faCarBus,
    'Wastewater': faToilet,
    'Water': faFaucetDrip,
    'Infrastructure': faCarBuilding,
  };
  const phaseIconMap = {
    'P1: Planning': clipboard,
    'P2: Design': create,
    'P3: Procurement': receipt,
    'P4: Construction': construct,
    'P5: Closeout': checkmarkCircle,
  };
  const phaseColorMap = {
    'P1: Planning': 'planning',
    'P2: Design': 'indesign',
    'P3: Procurement': 'tertiary',
    'P4: Construction': 'inconstruction',
    'P5: Closeout': 'completed',
  };

  const titleMap = {
    'Water': 'Drinking Water',
    'Parks & Recreation': 'Parks & Recreation',
    'Parking': 'Parking',
    'Convention Center & Tourism': 'Convention Center & Tourism',
    'Solid Waste & EPM': 'Solid Waste & EPM',
    'Stormwater Engineering': 'Stormwater',
    'Transportation': 'Transportation',
    'Wastewater': 'Wastewater',
    'Infrastructure': 'Infrastructure',
    'P1: Planning': 'In Planning',
    'P2: Design': 'In Design',
    'P3: Procurement': 'Procurement',
    'P4: Construction': 'In Construction',
    'P5: Closeout': 'Complete',
  };

  function dismissProjectFilter() {
    modalRef.current.dismiss();
  }

  return (
    <>
      <IonHeader className="modal-header ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            {/* {ios && (
              <IonButton onClick={() => dismissProjectFilter()}>
                Cancel
              </IonButton>
            )} */}
            {!ios && <IonButton onClick={handleSelectAll}>Reset</IonButton>}
          </IonButtons>
          <IonTitle>Filter Projects</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => dismissProjectFilter()} strong>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList className="radio-list">
          <IonListHeader style={{ marginBottom: '10px' }}>
            By Category
          </IonListHeader>
          {projectCategories.map(category => (
            <IonItem key={category}>
              <div slot="start">
                <FontAwesomeIcon
                  color="var(--ion-color-primary)"
                  icon={iconMap[category]}
                  style={{
                    fontSize: '26px',
                    maxWidth: '26px',
                    minWidth: '26px',
                  }}
                />
              </div>
              <IonLabel>{titleMap[category]}</IonLabel>
              <IonCheckbox
                onClick={() => toggleCategoryFilter(category)}
                checked={filteredProjectCategories.indexOf(category) !== -1}
                color="primary"
                value={category}
              ></IonCheckbox>
            </IonItem>
          ))}

          <IonListHeader style={{ marginBottom: '10px' }}>
            By Phase
          </IonListHeader>
          {projectPhases.map(phase => (
            <IonItem key={phase}>
              <IonIcon
                color={phaseColorMap[phase]}
                slot="start"
                style={{
                  minWidth: '30px',
                  fontSize: '24px',
                  marginInlineEnd: '20px',
                  marginInlineStart: '0px',
                }}
                icon={phaseIconMap[phase]}
              />
              <IonLabel>{titleMap[phase]}</IonLabel>
              <IonCheckbox
                onClick={() => togglePhaseFilter(phase)}
                checked={filteredProjectPhases.indexOf(phase) !== -1}
                color="primary"
                value={phase}
              ></IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>

      {ios && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={handleDeselectAll}>Deselect All</IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSelectAll}>Select All</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </>
  );
};

export default connect({
  mapStateToProps: state => ({
    projectCategories: state.data.projectCategories,
    projectPhases: state.data.projectPhases,
    filteredProjectCategories: state.data.filteredProjectCategories,
    filteredProjectPhases: state.data.filteredProjectPhases,
  }),
  mapDispatchToProps: {
    updateFilteredProjectCategories,
    updateFilteredProjectPhases,
  },
  component: ProjectFilter,
});
