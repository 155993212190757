import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonIcon,
  getConfig,
  useIonAlert,
  isPlatform,
} from '@ionic/react';
import { star, starOutline } from 'ionicons/icons';
import { connect } from '../data/connect';
import {
  addFavorite,
  addSubscription,
  removeFavorite,
  removeSubscription,
} from '../data/user/user.actions';
import { hapticsImpact } from '../services/hapticsApi';
import { OpenNativeSettings } from '@ionic-native/open-native-settings';

const FavoriteToggle = ({
  projid,
  projname,
  isFavorite,
  isCard,
  mode,
  addFavorite,
  removeFavorite,
  addSubscription,
  removeSubscription,
}) => {
  const [present] = useIonAlert();

  const [notificationState, setNotificationState] = useState();

  useEffect(() => {
    if (isPlatform('capacitor')) {
      window['plugins'].OneSignal.getDeviceState(function (state) {
        console.log('OneSignal Device State: ' + JSON.stringify(state));
        setNotificationState(state);
      });
      window['plugins'].OneSignal.addPermissionObserver(function (state) {
        window['plugins'].OneSignal.getDeviceState(function (state) {
          console.log('OneSignal Device State: ' + JSON.stringify(state));
          setNotificationState(state);
        });
      });
    }
    return () => {
      setNotificationState();
    };
  }, []);

  const handleAddFavorite = async (id, name) => {
    await addFavorite(id);
    await hapticsImpact('medium');
    if (isPlatform('capacitor')) {
      await window['plugins'].OneSignal.sendTag(name, 1);
    }
    if (notificationState && !notificationState.hasNotificationPermission) {
      present({
        header: 'Notifications Disabled',
        message:
          'Turn on notifications in device settings to enable alerts for this project',
        buttons: [
          'Not Now',
          {
            text: 'Settings',
            handler: () =>
              OpenNativeSettings.open(
                'application_details',
                function () {
                  console.log('opened location settings');
                },
                function () {
                  console.log('failed to open location settings');
                },
              ),
          },
        ],
        onDidDismiss: e => console.log('did dismiss'),
      });
    } else {
      await present({
        header: 'Project Alerts',
        message: 'Enable notifications for this project?',
        buttons: [
          'No',
          {
            text: 'Yes',
            handler: () => {
              addSubscription(id);
              if (isPlatform('capacitor')) {
                window['plugins'].OneSignal.sendTag(name, 2);
              }
            },
          },
        ],
        onDidDismiss: e => console.log('did dismiss'),
      });
    }
  };
  const handleRemoveFavorite = async (id, name) => {
    await removeFavorite(id);
    await removeSubscription(id);
    await hapticsImpact('medium');
    if (isPlatform('capacitor')) {
      await window['plugins'].OneSignal.deleteTag(name);
    }
  };

  const toggleFavorite = () => {
    isFavorite
      ? handleRemoveFavorite(
          projid,
          projname.replace(/\s+/g, '-').toLowerCase(),
        )
      : handleAddFavorite(projid, projname.replace(/\s+/g, '-').toLowerCase());
  };

  return (
    <IonButton
      fill="clear"
      onClick={toggleFavorite}
      className="favorite-toggle"
      color={
        isFavorite
          ? mode === 'ios'
            ? 'warning'
            : 'warning'
          : mode === 'ios'
          ? 'primary'
          : 'white'
      }
      mode="ios"
    >
      {isFavorite ? (
        <IonIcon
          slot="icon-only"
          icon={star}
          style={{ fontSize: isCard ? '24px' : '28px' }}
        ></IonIcon>
      ) : (
        <IonIcon
          slot="icon-only"
          icon={starOutline}
          style={{ fontSize: isCard ? '24px' : '28px' }}
        ></IonIcon>
      )}
    </IonButton>
  );
};

export default connect({
  mapStateToProps: state => ({
    mode: getConfig().get('mode'),
  }),
  mapDispatchToProps: {
    addFavorite,
    removeFavorite,
    addSubscription,
    removeSubscription,
  },
  component: React.memo(FavoriteToggle),
});
