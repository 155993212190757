/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonCardHeader,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  useIonViewDidEnter,
  getConfig,
  IonModal,
} from '@ionic/react';
import { setShowModal } from '../../../data/app-data/app-data.actions';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { call, mail, send } from 'ionicons/icons';
import { connect } from '../../../data/connect';
import ContactForm from '../../Modals/ContactForm';

const Contact = ({ project, hide, pageRef, mode }) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Project Contact',
      nameOverride: 'Project Contact',
    });
  });

  const contactFormModal = useRef(null);

  return (
    <div id="project-contact-tab" style={hide ? { display: 'none' } : {}}>
      <div style={{ margin: '-5px' }}></div>
      <IonCard
        className="contact-card"
        style={{ marginTop: '14px', paddingTop: '0px', paddingBottom: '8px' }}
      >
        <IonCardHeader
          style={{
            paddingTop: mode === 'ios' ? '12px' : '5px',
            marginBottom: '-10px',
          }}
        >
          <IonCardSubtitle color="primary" style={{ marginTop: '6px' }}>
            Representative
          </IonCardSubtitle>
          <IonCardTitle style={{ fontSize: '22px' }}>
            {project.pocname}
          </IonCardTitle>
        </IonCardHeader>

        <IonItem detail button href={`tel:${project.pocphone}`}>
          <IonIcon
            color="primary"
            icon={call}
            style={{ marginRight: '16px' }}
          />
          <IonLabel style={{ fontSize: '16px' }}>{project.pocphone}</IonLabel>
        </IonItem>

        <IonItem detail button href={`mailto:${project.pocemail}`}>
          <IonIcon
            color="primary"
            icon={mail}
            style={{ marginRight: '16px' }}
          />
          <IonLabel style={{ fontSize: '16px' }}>{project.pocemail}</IonLabel>
        </IonItem>
        <IonItem detail button id="open-contact-form-modal" lines="none">
          <IonIcon
            color="primary"
            icon={send}
            style={{ marginRight: '16px' }}
          />
          <IonLabel style={{ fontSize: '16px' }}>Send A Message</IonLabel>
        </IonItem>
        <IonModal
          ref={contactFormModal}
          trigger="open-contact-form-modal"
          presentingElement={pageRef.current}
        >
          <ContactForm modalRef={contactFormModal} project={project} />
        </IonModal>
      </IonCard>
    </div>
  );
};

export default connect({
  mapStateToProps: (state, OwnProps) => ({
    darkMode: state.user.darkMode,
    mode: getConfig().get('mode'),
  }),
  mapDispatchToProps: {
    setShowModal,
  },
  component: Contact,
});
