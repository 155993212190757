import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  getConfig,
} from '@ionic/react';
import { addFavorite, removeFavorite } from '../../data/user/user.actions';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import NotificationToggle from '../../components/NotificationToggle';
import ProjectCard from '../../components/ProjectList/ProjectCard';
import { createParagraphs } from '../../services/textFormatters';
import FavoriteToggle from '../../components/FavoriteToggle';
import ShareButton from '../../components/ShareButton';
import { connect } from '../../data/connect';
import Details from './Tabs/Details';
import Contact from './Tabs/Contact';
import Alerts from './Tabs/Alerts';
import './ProjectPage.scss';

const ProjectPage = ({
  projects,
  mode,
  favoritedProjects,
  subscribedProjects,
}) => {
  const ios = mode === 'ios';
  const params = useParams();
  const pageRef = useRef(null);
  const location = useLocation();
  const project = projects.find(
    p => p.attributes.projid === params.id,
  ).attributes;
  const [tab, setTab] = useState('Details');
  const handleTabChange = e => {
    setTab(e.detail.value);
  };
  useEffect(() => {
    FirebaseAnalytics.setScreenName({
      screenName: `Project ${tab}`,
      nameOverride: `Project ${tab}`,
    });
  }, [tab]);

  const [subProjects, setSubProjects] = useState();
  useEffect(() => {
    if (project.subprojects === 'yes') {
      var str = project.projid;
      str = str.substring(str.indexOf('.') + 1);
      setSubProjects(projects.filter(p => p.attributes.projid.startsWith(str)));
    }
    return () => {
      setSubProjects(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <IonPage id="project-page" ref={pageRef}>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tabs/projects"
              text={ios ? 'Projects' : ''}
            ></IonBackButton>
          </IonButtons>
          <IonTitle>
            {ios ? tab : project.projname.split('_')[0].replace('-', ' ')}
          </IonTitle>
          <IonButtons slot="end">
            {!project.subprojects && (
              <>
                <NotificationToggle
                  projid={project.projid}
                  projname={project.projname}
                  isSubscribed={subscribedProjects.indexOf(project.projid) > -1}
                  isFavorite={favoritedProjects.indexOf(project.projid) > -1}
                />
                <FavoriteToggle
                  projid={project.projid}
                  projname={project.projname}
                  isFavorite={favoritedProjects.indexOf(project.projid) > -1}
                />
              </>
            )}
            <ShareButton
              title={project.projname}
              text="Check out this project from Tampa CIP"
              url={`https://tampacip.app${location.pathname}`}
              dialogTitle="Share Project"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {!ios && !project.subprojects && (
          <IonToolbar mode="ios" className="segment-toolbar">
            <IonSegment
              mode="ios"
              className="md-segment"
              value={tab}
              onIonChange={e => handleTabChange(e)}
            >
              <IonSegmentButton className="md-segment-button" value="Details">
                Details
              </IonSegmentButton>
              <IonSegmentButton className="md-segment-button" value="Alerts">
                Alerts
              </IonSegmentButton>
              <IonSegmentButton className="md-segment-button" value="Contact">
                Contact
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        )}
        {ios && (
          <IonHeader collapse="condense" className="ion-no-border">
            <IonToolbar className="color-bg">
              <div className="projname-block">
                <h1> {project.projname.split('_')[0].replace('-', ' ')}</h1>
              </div>
            </IonToolbar>
            {!project.subprojects && (
              <>
                <IonToolbar className="color-bg">
                  <IonSegment value={tab} onIonChange={e => handleTabChange(e)}>
                    <IonSegmentButton value="Details" className="custom">
                      Details
                    </IonSegmentButton>
                    <IonSegmentButton value="Alerts" className="custom">
                      Alerts
                    </IonSegmentButton>
                    <IonSegmentButton value="Contact" className="custom">
                      Contact
                    </IonSegmentButton>
                  </IonSegment>
                </IonToolbar>
              </>
            )}
          </IonHeader>
        )}
        {!project.subprojects && (
          <>
            <Details
              project={project}
              pageRef={pageRef}
              hide={tab !== 'Details'}
            />

            <Alerts
              projid={project.projid}
              projectName={project.projname}
              hide={tab !== 'Alerts'}
            />

            <Contact
              project={project}
              hide={tab !== 'Contact'}
              pageRef={pageRef}
            />
          </>
        )}
        {project.subprojects && subProjects && (
          <>
            {project.projdesc && (
              <>
                <div
                  style={{ marginTop: '10px' }}
                  className="line-divider"
                ></div>
                <div style={{ margin: '12px 16px 16px 16px' }}>
                  <h4>Description</h4>
                </div>
                <div style={{ margin: '10px 16px 16px 16px' }}>
                  <p
                    dangerouslySetInnerHTML={createParagraphs(project.projdesc)}
                  />
                </div>
                <div className="line-divider"></div>
                <div style={{ margin: '12px 16px 23px 16px' }}>
                  <h4>Projects</h4>
                </div>
              </>
            )}

            {subProjects.map(p => {
              return (
                <ProjectCard
                  key={p.attributes.projid}
                  projid={p.attributes.projid}
                  projname={p.attributes.projname}
                  projphase={p.attributes.projphase}
                  projtype={p.attributes.projtype}
                  EditDate={p.attributes.EditDate}
                  isFavorite={
                    favoritedProjects.indexOf(p.attributes.projid) > -1
                  }
                />
              );
            })}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state, OwnProps) => ({
    projects: state.data.projects,
    mode: getConfig().get('mode'),
    favoritedProjects: state.user.favoritedProjects,
    subscribedProjects: state.user.subscribedProjects,
  }),
  mapDispatchToProps: {
    addFavorite,
    removeFavorite,
  },
  component: ProjectPage,
});
