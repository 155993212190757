import { createSelector } from 'reselect';
// PROJECTS
const getProjects = state => {
  return state.data.projects;
};

const getFilteredProjectCategories = state =>
  state.data.filteredProjectCategories;

const getFilteredProjectPhases = state => state.data.filteredProjectPhases;

const getProjectSearchText = state => state.data.projectSearchText;

const getAlertFeed = state => {
  return state.data.alertFeed;
};

export const getAlerts = state => state.data.alerts;

const getFilteredAlertCategories = state => state.data.filteredAlertCategories;

const getAlertSearchText = state => state.data.alertSearchText;

const getFavoriteProjectIds = state => state.user.favoritedProjects;

export const getNotificationPreferences = state =>
  state.user.notificationPreferences;

// PROJECTS CIP
export const getFilteredProjects = createSelector(
  getProjects,
  getFilteredProjectCategories,
  getFilteredProjectPhases,
  (projects, filteredProjectCategories, filteredProjectPhases) => {
    const filteredProjects = [];
    projects.forEach(project => {
      const categories = project.attributes.projtype.split();
      const phase = project.attributes.projphase;
      categories.forEach(category => {
        if (filteredProjectCategories.indexOf(category) > -1) {
          if (filteredProjects.some(p => p.projid === project.projid)) {
            return;
          } else {
            if (filteredProjectPhases.indexOf(phase) > -1) {
              if (filteredProjects.some(p => p.projid === project.projid)) {
                return;
              } else {
                filteredProjects.push(project.attributes);
              }
            }
          }
        }
      });
    });

    return {
      filteredProjects,
    };
  },
);

export const getSearchedProjects = createSelector(
  getFilteredProjects,
  getProjectSearchText,
  (projects, projectSearchText) => {
    if (!projectSearchText) {
      return projects;
    }

    const filteredProjects = projects.filteredProjects.filter(
      p =>
        p.projname.toLowerCase().indexOf(projectSearchText.toLowerCase()) > -1,
    );

    return {
      filteredProjects,
    };
  },
);

export const getProjectList = createSelector(
  getSearchedProjects,
  projects => projects,
);

export const getFavoritedProjects = createSelector(
  getProjectList,
  getFavoriteProjectIds,
  (projects, favoriteProjectIds) => {
    const filteredProjects = projects.filteredProjects.filter(
      s => favoriteProjectIds.indexOf(s.projid) > -1,
    );
    return {
      filteredProjects,
    };
  },
);

// ALERTS
export const getFilteredAlerts = createSelector(
  getAlertFeed,
  getFilteredAlertCategories,
  (alertFeed, filteredAlertCategories) => {
    const projectGroups = [];
    alertFeed.projectGroups.forEach(group => {
      const alerts = [];
      group.alerts.forEach(alert => {
        alert.categories.forEach(category => {
          if (filteredAlertCategories.indexOf(category) > -1) {
            alerts.push(alert);
          }
        });
      });
      if (alerts.length) {
        const projectGroupToAdd = {
          project: group.project,
          alerts,
        };
        projectGroups.push(projectGroupToAdd);
      }
    });
    return {
      date: alertFeed.date,
      projectGroups,
    };
  },
);

export const getSearchedAlerts = createSelector(
  getFilteredAlerts,
  getAlertSearchText,
  (alertFeed, alertSearchText) => {
    if (!alertSearchText) {
      return alertFeed;
    }
    const projectGroups = [];
    alertFeed.projectGroups.forEach(group => {
      const alerts = group.alerts.filter(
        a =>
          a.description.toLowerCase().indexOf(alertSearchText.toLowerCase()) >
          -1,
      );
      if (alerts.length) {
        const projectGroupToAdd = {
          project: group.project,
          alerts,
        };
        projectGroups.push(projectGroupToAdd);
      }
    });
    return {
      date: alertFeed.date,
      projectGroups,
    };
  },
);

export const getAlertList = createSelector(
  getSearchedAlerts,
  alertFeed => alertFeed,
);

export const getFolliwngAlerts = createSelector(
  getAlertList,
  getFavoriteProjectIds,
  (alertFeed, favoriteProjectIds) => {
    const projectGroups = [];
    alertFeed.projectGroups.forEach(group => {
      const alerts = group.alerts.filter(
        a => favoriteProjectIds.indexOf(a.projectId) > -1,
      );
      if (alerts.length) {
        const projectGroupToAdd = {
          project: group.project,
          alerts,
        };
        projectGroups.push(projectGroupToAdd);
      }
    });
    return {
      date: alertFeed.date,
      projectGroups,
    };
  },
);

const getIdParam = (_state, props) => {
  return props.match.params['id'];
};

const getAlertIdParam = (_state, props) => {
  return props.match.params['alertId'];
};

const getProjectIdProp = (_state, props) => {
  return props.project.projid;
};

export const getProject = createSelector(
  getProjects,
  getIdParam,
  (projects, id) => {
    return projects.find(p => p.slug === id);
  },
);
export const getProjectAttachments = state => {
  return state.data.projectAttachments;
};

export const getAlert = createSelector(
  getAlerts,
  getAlertIdParam,
  (alerts, id) => {
    // console.log(alerts);
    return alerts.find(a => a.attributes.GlobalID === id);
  },
);

export const getAlertAttachments = state => {
  return state.data.alertAttachments;
};

export const getProjectAlerts = createSelector(
  getAlerts,
  getIdParam,
  (alerts, id) => {
    return alerts.filter(a => id.indexOf(a.projectSlug) > -1);
  },
);

export const getProjectAlertCount = createSelector(
  getAlerts,
  getProjectIdProp,
  (alerts, id) => {
    const projectAlerts = alerts.filter(a => id.indexOf(a.projectSlug) > -1);
    return Object.keys(projectAlerts).length;
  },
);
