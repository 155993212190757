import React from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonCardContent,
} from '@ionic/react';
import ProjectStatus from '../ProjectStatus';
import ProjectCategory from '../ProjectCategory';
import TimeSince from '../TimeSince';

const ProjectCard = ({ projid, projname, projphase, projtype, EditDate }) => {
  return (
    <>
      <IonCard
        button
        className="project-card"
        routerLink={`/tabs/projects/${projid}`}
      >
        <IonCardHeader>
          <div
            className=""
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <ProjectCategory category={projtype} />
            <ProjectStatus status={projphase} marginLeft="-1" isCard />
          </div>
          <IonCardTitle className="card-title" style={{ marginTop: '5px' }}>
            {projname}
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <div className="card-footer">
            <div>
              {EditDate && <TimeSince date={EditDate} actionText="Updated" />}
            </div>
            <IonButton size="small" fill="clear" strong className="see-more">
              Learn More
            </IonButton>
          </div>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default React.memo(ProjectCard);
