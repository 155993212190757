import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from '@ionic/react';
import MobileStoreButton from 'react-mobile-store-button';
import './StoreRedirect.scss';

declare global {
  interface Window {
    MSStream: any;
  }
}

const RedirectPage = () => {
  const [isRedirecting, setIsRedirecting] = useState(true);
  useEffect(() => {
    detectDeviceAndRedirect();
  }, []);

  const iOSUrl = 'https://apps.apple.com/us/app/tampa-cip/id6443905622';
  const AndroidUrl =
    'https://play.google.com/store/apps/details?id=com.tampacip.app';

  const detectDeviceAndRedirect = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Check if the user is on an iPhone or iPad
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = iOSUrl;
      return;
    }

    // Check if the user is on an Android device
    if (/android/i.test(userAgent)) {
      window.location.href = AndroidUrl;
      return;
    }

    // If neither, you can direct them to a generic download page or give some info
    alert(
      'Please visit the App Store or Google Play Store to download the Tampa CIP app.',
    );
    setIsRedirecting(false);
  };

  return (
    <IonPage id="storeRedirect">
      <IonContent className="ion-padding">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {isRedirecting ? (
            <>
              <IonSpinner name="crescent" />
              <p>Redirecting...</p>
            </>
          ) : (
            <div>
              <IonGrid>
                <IonRow
                  class="ion-justify-content-center"
                  style={{ marginBottom: '20px' }}
                >
                  <IonText>
                    <h1 style={{ fontWeight: '700' }}>Download The App</h1>
                  </IonText>
                </IonRow>
                <IonRow class="ion-justify-content-center">
                  <img
                    src="assets/img/slide-1.png"
                    className="slide-image"
                    alt="Mobile phone with the Tampa CIP app loading screen"
                  />
                </IonRow>
                <IonRow class="ion-justify-content-center">
                  <IonCol style={{ textAlign: 'center' }}>
                    <MobileStoreButton
                      store="ios"
                      url={iOSUrl}
                      linkProps={{ title: 'iOS Store Button' }}
                    />
                  </IonCol>
                  <IonCol style={{ textAlign: 'center' }}>
                    <MobileStoreButton
                      store="android"
                      url={AndroidUrl}
                      linkProps={{ title: 'Google Play Store Button' }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RedirectPage;
