/* eslint-disable default-case */

export const appReducer = (state, action) => {
  switch (action.type) {
    case 'set-app-loading': {
      return { ...state, loading: action.isLoading };
    }
    case 'set-app-data': {
      return { ...state, ...action.data };
    }
    case 'set-network-status': {
      return { ...state, networkStatus: action.networkStatus };
    }
    case 'update-filtered-project-phases': {
      return {
        ...state,
        filteredProjectPhases: action.filteredProjectPhases,
      };
    }
    case 'update-filtered-project-categories': {
      return {
        ...state,
        filteredProjectCategories: action.filteredProjectCategories,
      };
    }

    case 'update-filtered-alert-categories': {
      return {
        ...state,
        filteredAlertCategories: action.filteredAlertCategories,
      };
    }
    case 'set-project-search-text': {
      return { ...state, projectSearchText: action.projectSearchText };
    }
    case 'set-alert-search-text': {
      return { ...state, alertSearchText: action.alertSearchText };
    }
    case 'set-show-modal': {
      return { ...state, showModal: action.showModal };
    }
    case 'set-show-2nd-modal': {
      return { ...state, show2ndModal: action.show2ndModal };
    }
  }
};
