import { IonIcon, IonChip, IonLabel } from '@ionic/react';
import {
  create,
  checkmarkCircle,
  construct,
  clipboard,
  receipt,
} from 'ionicons/icons';

const ProjectStatus = ({ status, isCard }) => {
  return (
    <IonChip
      className="status"
      color="white"
      style={{
        height: '20px',
        float: isCard ? 'right' : 'left',
        marginTop: '0px',
        marginInline: isCard ? '-2px' : 'initial',
        background: `${
          status.includes('Construction')
            ? `var(--ion-color-inconstruction)`
            : status.includes('Design')
            ? `var(--ion-color-indesign)`
            : status.includes('Closeout')
            ? `var(--ion-color-completed)`
            : status.includes('Planning')
            ? `var(--ion-color-planning)`
            : `var(--ion-color-tertiary)`
        }`,
      }}
    >
      <IonIcon
        style={{
          marginInlineEnd: '4px',
          marginInlineStart: '-3px',
          fontSize: isCard ? '11px' : '12px',
        }}
        icon={
          status.includes('Construction')
            ? construct
            : status.includes('Design')
            ? create
            : status.includes('Closeout')
            ? checkmarkCircle
            : status.includes('Planning')
            ? clipboard
            : receipt
        }
      />
      <IonLabel
        style={{
          fontSize: isCard ? '11px' : '12px',
          marginTop: '0px',
          fontWeight: '600',
          overflow: 'visible',
        }}
      >
        {status.includes('Construction')
          ? 'In Construction'
          : status.includes('Design')
          ? 'In Design'
          : status.includes('Closeout')
          ? 'Completed'
          : status.includes('Planning')
          ? 'In Planning'
          : 'In Procurement'}
      </IonLabel>
    </IonChip>
  );
};

export default ProjectStatus;
