import { Share } from '@capacitor/share';
import { isPlatform } from '@ionic/react';

export const share = async (title, text, url, dialogTitle) => {
  if (!isPlatform('capacitor')) {
    return;
  }
  await Share.share({
    title,
    text,
    url,
    dialogTitle,
  });
};
