import { IonContent, IonPage } from '@ionic/react';
import './ExploreTab.scss';

const ExploreTab = () => {
  return (
    <IonPage id="map-view">
      <IonContent
        slot="fixed"
        class="map-page"
        fullscreen={true}
        force-overscroll={false}
      ></IonContent>
    </IonPage>
  );
};

export default ExploreTab;
