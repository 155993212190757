/* eslint-disable default-case */
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { isPlatform } from '@ionic/react';

export const hapticsImpact = style => {
  if (!isPlatform('capacitor')) {
    return;
  }
  switch (style) {
    case 'light':
      Haptics.impact({
        style: ImpactStyle.Light,
      });
      break;
    case 'medium':
      Haptics.impact({
        style: ImpactStyle.Medium,
      });
      break;
    case 'heavy':
      Haptics.impact({
        style: ImpactStyle.Heavy,
      });
      break;
  }
};
