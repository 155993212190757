import { SplashScreen } from '@capacitor/splash-screen';
import {
  getAppData,
  getBackupAppData,
  setBackupAppData,
  setFilteredProjectPhasesData,
  setFilteredProjectCategoriesData,
  setFilteredAlertCategoriesData,
} from '../dataApi';

export const loadAppData = network => async dispatch => {
  dispatch(setLoading(true));
  if (network) {
    await getAppData(true)
      .then(data => {
        dispatch(setAppData(data));
        setBackupAppData(data);
        dispatch(setLoading(false));
        setTimeout(function () {
          SplashScreen.hide({ fadeOutDuration: 300 });
        }, 300);
      })
      .catch(async function () {
        // console.log('Data Fetch Catch Error');
        dispatch(setNetworkStatus(true));
        const backupData = await getBackupAppData();
        if (backupData) {
          // backup data found
          dispatch(setAppData(backupData));
          dispatch(setLoading(false));
          setTimeout(function () {
            SplashScreen.hide({ fadeOutDuration: 300 });
          }, 300);
        } else if (!backupData) {
          // no backup data
          await getAppData(false).then(data => {
            dispatch(setAppData(data));
            setBackupAppData(data);
            dispatch(setLoading(false));
            setTimeout(function () {
              SplashScreen.hide({ fadeOutDuration: 300 });
            }, 300);
          });
        }
      });
  } else {
    const backupData = await getBackupAppData();
    if (backupData) {
      // backup data found
      dispatch(setAppData(backupData));
      dispatch(setLoading(false));
      setTimeout(function () {
        SplashScreen.hide({ fadeOutDuration: 300 });
      }, 300);
    } else if (!backupData) {
      // no backup data
      await getAppData(false)
        .then(data => {
          dispatch(setAppData(data));
          setBackupAppData(data);
          dispatch(setLoading(false));
        })
        .finally(() => {
          setTimeout(function () {
            SplashScreen.hide({ fadeOutDuration: 300 });
          }, 300);
        });
    }
  }
};
export const setLoading = isLoading => ({
  type: 'set-app-loading',
  isLoading,
});
export const setAppData = data => ({
  type: 'set-app-data',
  data,
});
export const setNetworkStatus = networkStatus => ({
  type: 'set-network-status',
  networkStatus,
});
export const updateFilteredProjectPhases =
  filteredProjectPhases => async dispatch => {
    await setFilteredProjectPhasesData(filteredProjectPhases);
    return {
      type: 'update-filtered-project-phases',
      filteredProjectPhases,
    };
  };
export const updateFilteredProjectCategories =
  filteredProjectCategories => async dispatch => {
    await setFilteredProjectCategoriesData(filteredProjectCategories);
    return {
      type: 'update-filtered-project-categories',
      filteredProjectCategories,
    };
  };
export const updateFilteredAlertCategories =
  filteredAlertCategories => async dispatch => {
    await setFilteredAlertCategoriesData(filteredAlertCategories);
    return {
      type: 'update-filtered-alert-categories',
      filteredAlertCategories,
    };
  };
export const setProjectSearchText = projectSearchText => ({
  type: 'set-project-search-text',
  projectSearchText,
});
export const setAlertSearchText = alertSearchText => ({
  type: 'set-alert-search-text',
  alertSearchText,
});
export const setShowModal = showModal => ({
  type: 'set-show-modal',
  showModal,
});
export const setShow2ndModal = show2ndModal => ({
  type: 'set-show-2nd-modal',
  show2ndModal,
});
