import React, { useEffect, useState } from 'react';
import {
  isPlatform,
  useIonViewDidEnter,
  useIonAlert,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonList,
  IonLabel,
  IonItem,
  IonToggle,
  IonButton,
  IonIcon,
  getConfig,
} from '@ionic/react';
import { OpenNativeSettings } from '@ionic-native/open-native-settings';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { updateEnabledNotifications } from '../../data/user/user.actions';
import { connect } from '../../data/connect';
import {
  car,
  bulbOutline,
  peopleOutline,
  sparklesOutline,
  thumbsUpOutline,
  constructOutline,
  informationCircleOutline,
  notificationsOutline,
} from 'ionicons/icons';
import './Preferences.scss';

const notificationTags = [
  {
    value: 'project_updates',
    title: 'Project Updates',
    icon: informationCircleOutline,
  },
  {
    value: 'public_meetings',
    title: 'Public Meetings',
    icon: peopleOutline,
  },
  {
    value: 'construction_notices',
    title: 'Construction Notices',
    icon: constructOutline,
  },
  {
    value: 'traffic_alerts',
    title: 'Traffic Alerts',
    icon: car,
  },
  {
    value: 'suggested_projects',
    title: 'Suggested Projects',
    icon: bulbOutline,
  },
  {
    value: 'surveys_feedback',
    title: 'Surveys Feedback',
    icon: thumbsUpOutline,
  },
  {
    value: 'new_features',
    title: 'New Features',
    icon: sparklesOutline,
  },
];

const Notifications = ({
  enabledNotifications,
  updateEnabledNotifications,
  modalRef,
  mode,
}) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Notifications',
      nameOverride: 'Notifications',
    });
  });

  function dismiss() {
    modalRef.current.dismiss();
  }

  const [present] = useIonAlert();
  const [notificationState, setNotificationState] = useState();
  useEffect(() => {
    if (isPlatform('capacitor')) {
      window['plugins'].OneSignal.getDeviceState(function (state) {
        console.log('OneSignal Device State: ' + JSON.stringify(state));
        setNotificationState(state);
      });
      window['plugins'].OneSignal.addPermissionObserver(function (state) {
        window['plugins'].OneSignal.getDeviceState(function (state) {
          console.log('OneSignal Device State: ' + JSON.stringify(state));
          setNotificationState(state);
        });
      });
      window['plugins'].OneSignal.addSubscriptionObserver(function (state) {
        window['plugins'].OneSignal.getDeviceState(function (state) {
          console.log('OneSignal Device State: ' + JSON.stringify(state));
          setNotificationState(state);
        });
      });
    }
    return () => {
      setNotificationState();
      // window['plugins'].OneSignal.removePermissionObserver();
    };
  }, []);

  useEffect(() => {
    if (notificationState && !notificationState.hasNotificationPermission) {
      // console.log('NOTIFICATION PERMISSION FALSE');
      present({
        header: 'Enable Notifications',
        message:
          'Turn on notifications in device settings to receive alerts from Tampa CIP ',
        buttons: [
          'Cancel',
          {
            text: 'Settings',
            handler: () =>
              OpenNativeSettings.open(
                'application_details',
                function () {
                  console.log('opened location settings');
                },
                function () {
                  console.log('failed to open location settings');
                },
              ),
          },
        ],
        onDidDismiss: e => console.log('did dismiss'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState]);

  const toggleNotificationPreference = preference => {
    if (enabledNotifications.indexOf(preference) > -1) {
      if (isPlatform('capacitor')) {
        window['plugins'].OneSignal.deleteTag(preference);
        // window['plugins'].OneSignal.sendTag(preference, 0);
      }
      updateEnabledNotifications(
        enabledNotifications.filter(x => x !== preference),
      );
    } else {
      if (isPlatform('capacitor')) {
        window['plugins'].OneSignal.sendTag(preference, 1);
      }
      updateEnabledNotifications([...enabledNotifications, preference]);
    }
  };

  // const [disabled, setDisabled] = useState(false);

  return (
    <IonPage id="preferences-page">
      <IonHeader className="modal-header ion-no-border">
        <IonToolbar className="main-menu">
          <IonTitle>Notifications</IonTitle>
          <IonButtons slot={mode === 'ios' ? 'start' : 'end'}>
            <IonButton onClick={() => dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* FOR WEB PREVIEW ONLY */}
        {/* <IonList>
          <IonItem>
            <IonIcon slot="start" color="primary" icon={notificationsOutline} />
            <IonToggle onClick={() => setDisabled(!disabled)} slot="end" />
            <IonLabel>Disable All</IonLabel>
          </IonItem>
          <br />
          {notificationTags.map(tag => (
            <IonItem key={tag.value} disabled={disabled}>
              <IonIcon slot="start" color="primary" icon={tag.icon} />
              <IonToggle
                onClick={() => toggleNotificationPreference(tag.value)}
                value={tag.value}
                checked={enabledNotifications.indexOf(tag.value) !== -1}
                slot="end"
              />
              <IonLabel>{tag.title}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}
        {/* FOR PRODUCTION ONLY */}
        {notificationState && (
          <IonList>
            <IonItem>
              <IonIcon
                slot="start"
                color="primary"
                icon={notificationsOutline}
              />
              <IonToggle
                onClick={() =>
                  window['plugins'].OneSignal.disablePush(
                    !notificationState.pushDisabled,
                  )
                }
                value={notificationState.pushDisabled}
                checked={notificationState.pushDisabled}
                slot="end"
              />
              <IonLabel>Disable All</IonLabel>
            </IonItem>
            <br />
            {notificationTags.map(tag => (
              <IonItem
                key={tag.value}
                disabled={
                  !notificationState.hasNotificationPermission ||
                  notificationState.pushDisabled === true
                }
              >
                <IonIcon slot="start" color="primary" icon={tag.icon} />
                <IonToggle
                  onClick={() => toggleNotificationPreference(tag.value)}
                  value={tag.value}
                  checked={enabledNotifications.indexOf(tag.value) !== -1}
                  slot="end"
                />
                <IonLabel>{tag.title}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: state => ({
    enabledNotifications: state.user.enabledNotifications,
    // notificationPermission: state.user.notificationPermission,
    mode: getConfig().get('mode'),
  }),
  mapDispatchToProps: {
    updateEnabledNotifications,
  },
  component: Notifications,
});
