import React, { useRef } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonContent,
  IonPage,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  useIonViewDidEnter,
  IonButton,
  IonButtons,
  getConfig,
  IonModal,
} from '@ionic/react';
import {
  globeOutline,
  libraryOutline,
  moonOutline,
  notificationsOutline,
} from 'ionicons/icons';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { connect } from '../../data/connect';
import { openBrowser } from '../../services/browserApi';
import DisplayTheme from './DisplayTheme';
import Notifications from './Notifications';
import TermsDefinitions from './TermsDefinitions';
import TermsConditions from './TermsConditions';

const Preferences = ({ mode, modalRef }) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Preferences',
      nameOverride: 'Preferences',
    });
  });

  const themeModal = useRef(null);
  const notificationsModal = useRef(null);
  const definitionsModal = useRef(null);
  const termsModal = useRef(null);
  const privacyModal = useRef(null);

  function dismissPreferences() {
    modalRef.current.dismiss();
  }

  // const [present, dismiss] = useIonModal(ModalContent, {
  //
  //   onDismiss: () => dismiss(),
  // });

  return (
    <IonPage id="preferences-page">
      <IonHeader className="modal-header ion-no-border">
        <IonToolbar className="main-menu">
          <IonTitle>Settings</IonTitle>
          <IonButtons slot={mode === 'ios' ? 'start' : 'end'}>
            <IonButton onClick={() => dismissPreferences()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList className="radio-list">
          <IonListHeader
            style={{
              paddingBottom: mode === 'ios' && '5px',
            }}
          >
            Preferences
          </IonListHeader>
          <IonItem detail id="open-theme-modal">
            <IonIcon slot="start" color="primary" icon={moonOutline} />
            <IonLabel>App Theme</IonLabel>
          </IonItem>
          <IonModal
            ref={themeModal}
            trigger="open-theme-modal"
            presentingElement={modalRef.current}
          >
            <DisplayTheme modalRef={themeModal} />
          </IonModal>
          <IonItem detail id="open-notifications-modal">
            <IonIcon slot="start" color="primary" icon={notificationsOutline} />
            <IonLabel>Notifications</IonLabel>
          </IonItem>
          <IonModal
            ref={notificationsModal}
            trigger="open-notifications-modal"
            presentingElement={modalRef.current}
          >
            <Notifications modalRef={notificationsModal} />
          </IonModal>
          <IonListHeader
            style={{
              paddingBottom: mode === 'ios' && '5px',
              marginTop: mode === 'ios' && '10px',
            }}
          >
            Resources
          </IonListHeader>
          <IonItem detail id="open-definitions-modal">
            <IonIcon slot="start" color="primary" icon={libraryOutline} />
            <IonLabel>Term Dictionary</IonLabel>
          </IonItem>
          <IonModal
            ref={definitionsModal}
            trigger="open-definitions-modal"
            presentingElement={modalRef.current}
          >
            <TermsDefinitions modalRef={definitionsModal} />
          </IonModal>
          <IonItem detail onClick={() => openBrowser('https://www.tampa.gov/')}>
            <IonIcon slot="start" color="primary" icon={globeOutline} />
            <IonLabel>City of Tampa Website</IonLabel>
          </IonItem>

          <IonListHeader
            style={{
              paddingBottom: mode === 'ios' && '5px',
              marginTop: mode === 'ios' && '10px',
            }}
          >
            About
          </IonListHeader>
          <IonItem detail id="open-terms-modal">
            <IonLabel>Terms &amp; Conditions</IonLabel>
          </IonItem>
          <IonModal
            ref={termsModal}
            trigger="open-terms-modal"
            presentingElement={modalRef.current}
          >
            <TermsConditions modalRef={termsModal} />
          </IonModal>
          <IonItem detail id="open-privacy-modal">
            <IonLabel>Privacy Policy</IonLabel>
          </IonItem>
          <IonModal
            ref={privacyModal}
            trigger="open-privacy-modal"
            presentingElement={modalRef.current}
          >
            <TermsConditions modalRef={privacyModal} />
          </IonModal>
          <IonItem>
            <IonLabel>App Version</IonLabel>
            <span slot="end">1.0.0</span>
          </IonItem>
        </IonList>
        <div id="map-logos" className="map-logos">
          <img src="assets/img/tampa-logo.svg" alt="" className="cot" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: state => ({
    mode: getConfig().get('mode'),
  }),
  component: React.memo(Preferences),
});
