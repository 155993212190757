import React from 'react';
import { IonLabel } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faTrashAlt,
  faHouseFlood,
  faSimCard,
  faCarBus,
  faToilet,
  faLandmark,
  faParking,
  faFaucetDrip,
  faCarBuilding,
  faBus,
  faFireSmoke,
  faCity,
  faHandHoldingUsd,
  faFileSignature,
  faGlobeAmericas,
  faTrees,
} from '@fortawesome/pro-solid-svg-icons';
import './ProjectCategory.scss';

const ProjectCategory = ({ category }) => {
  const iconMap = {
    'Art & Cultural Affairs': faGlobeAmericas,
    'Contract Administration': faFileSignature,
    'Convention Center & Tourism': faLandmark,
    'Economic Opportunity': faHandHoldingUsd,
    'Facility Management': faCity,
    'Fire': faFireSmoke,
    'Fleet Maintenance': faBus,
    'HR-Risk Management': faUsers,
    'Parking': faParking,
    'Parks & Recreation': faTrees,
    'Planning': 'Planning',
    'Police': 'Police',
    'Solid Waste & EPM': faTrashAlt,
    'Stormwater Engineering': faHouseFlood,
    'Technology & Innovation': faSimCard,
    'Transportation': faCarBus,
    'Wastewater': faToilet,
    'Water': faFaucetDrip,
    'Infrastructure': faCarBuilding,
  };

  const titleMap = {
    'Art & Cultural Affairs': 'Art & Cultural Affairs',
    'Contract Administration': 'Contract Administration',
    'Convention Center & Tourism': 'Convention Center & Tourism',
    'Economic Opportunity': 'Economic Opportunity',
    'Facility Management': 'Facility Management',
    'Fire': 'Fire',
    'Fleet Maintenance': 'Fleet Maintenance',
    'HR-Risk Management': 'HR-Risk Management',
    'Parking': 'Parking',
    'Parks & Recreation': 'Parks & Recreation',
    'Planning': 'Planning',
    'Police': 'Police',
    'Solid Waste & EPM': 'Solid Waste & EPM',
    'Stormwater Engineering': 'Stormwater',
    'Technology & Innovation': 'Technology & Innovation',
    'Transportation': 'Transportation',
    'Wastewater': 'Wastewater',
    'Water': 'Potable Water',
    'Infrastructure': 'Infrastructure',
  };

  return (
    <span className="span">
      <FontAwesomeIcon
        color="var(--ion-color-primary)"
        icon={iconMap[category]}
        style={{
          fontSize: '15px',
          maxWidth: '20px',
          minWidth: '20px',
          marginInlineEnd: '4px',
          marginInlineStart: '-8px',
          marginBottom: '-2px',
        }}
      />

      <IonLabel style={{ fontSize: '14px' }}>
        <div
          style={{
            display: 'inline-block',
            position: 'relative',
            top: '1px',
            left: '2px',
          }}
        >
          {titleMap[category]}
        </div>
      </IonLabel>
    </span>
  );
};

export default React.memo(ProjectCategory);
