import React from 'react';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import {
  compass,
  compassOutline,
  notifications,
  notificationsOutline,
  warning,
  warningOutline,
} from 'ionicons/icons';
import { Redirect, Route, useLocation } from 'react-router';
import ProjectsTab from '../pages/ProjectsTab/ProjectsTab';
import ExploreTab from '../pages/ExploreTab/ExploreTab';
import AlertsTab from '../pages/AlertsTab/AlertsTab';
import ProjectPage from '../pages/ProjectPage/ProjectPage';
import AlertPage from '../pages/AlertPage/AlertPage';
import { connect } from '../data/connect';

const MainTabs = ({ match, hasSeenTutorial }) => {
  const location = useLocation();
  return (
    <IonTabs>
      <IonRouterOutlet>
        {/* <Route path="/" render={() => <Redirect to="/tabs/projects" />} exact /> */}
        <Redirect exact path="/tabs" to="/tabs/projects" />
        <Route path="/tabs/projects" component={ProjectsTab} exact />
        <Route path="/tabs/projects/:id" component={ProjectPage} exact />
        <Route path="/tabs/explore" component={ExploreTab} exact />
        <Route path="/tabs/explore/project/:id" component={ProjectPage} exact />
        <Route path="/tabs/alerts" component={AlertsTab} exact />
        <Route path="/tabs/alerts/:alertId" component={AlertPage} exact />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" translucent={false} id="appTabBar">
        <IonTabButton tab="projects" href="/tabs/projects">
          <IonIcon
            // icon={warningOutline}
            icon={
              location.pathname.startsWith('/tabs/projects')
                ? warning
                : warningOutline
            }
          />
          <IonLabel>Projects</IonLabel>
        </IonTabButton>
        <IonTabButton tab="explore" href="/tabs/explore">
          <IonIcon
            // icon={compassOutline}
            icon={
              location.pathname.startsWith('/tabs/explore')
                ? compass
                : compassOutline
            }
          />
          <IonLabel>Explore</IonLabel>
        </IonTabButton>
        <IonTabButton tab="alerts" href="/tabs/alerts">
          <IonIcon
            // icon={notificationsOutline}
            icon={
              location.pathname.startsWith('/tabs/alerts')
                ? notifications
                : notificationsOutline
            }
          />
          <IonLabel>Alerts</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

// export default MainTabs;

export default connect({
  mapStateToProps: state => ({
    hasSeenTutorial: state.user.hasSeenTutorial,
  }),
  component: MainTabs,
});
