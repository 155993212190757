import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonButton,
  useIonViewDidEnter,
  getConfig,
} from '@ionic/react';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import ProjectStatus from '../../components/ProjectStatus';
import { connect } from '../../data/connect';
import './Preferences.scss';

const TermsDefinitions = ({ modalRef, mode }) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Terms & Definitions',
      nameOverride: 'Terms & Definitions',
    });
  });

  function dismiss() {
    modalRef.current.dismiss();
  }
  return (
    <IonPage id="preferences-page">
      <IonHeader className="modal-header ion-no-border">
        <IonToolbar>
          <IonTitle>Term Dictionary</IonTitle>
          <IonButtons slot={mode === 'ios' ? 'start' : 'end'}>
            <IonButton onClick={() => dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding-horizontal">
          <h2>Description</h2>
          <p>
            High-level summary of the project that may include the types of
            improvements included as part of the project.
          </p>
        </div>
        <div className="line-divider"></div>
        <div className="ion-padding-horizontal">
          <h2>Rationale</h2>
          <p>
            A concise explanation that describes the value of the project and
            why it is important/critical.
          </p>
        </div>
        <div className="line-divider"></div>
        <div className="ion-padding-horizontal">
          <h2>Construction Fiscal Year</h2>
          <p>
            This is the year that the project construction is slated to begin.
            Tampa’s fiscal year runs from October 1 – September 30.
          </p>
        </div>
        <div className="line-divider"></div>
        <div className="ion-padding-horizontal">
          <h2>Project Phase</h2>
          <p>
            Indicates how far a project is in its progress toward completion.
            The following stages describe the current projects and programs
            displayed in this app:
            <ul>
              <li>
                <ProjectStatus status={'Planning'} /> - The scope of the project
                is prepared and cost and construction dates are estimated.
              </li>
              <li>
                <ProjectStatus status={'Design'} /> - The project is being
                designed and funding sources are identified. Real estate
                acquisition is also part of this phase.
              </li>
              <li>
                <ProjectStatus status={'Procurement'} /> - The project is
                advertised for bidding and consultants/contractors go through
                the City’s selection process; contracts are negotiated and
                executed.
              </li>
              <li>
                <ProjectStatus status={'Construction'} /> - This stage includes
                any permitting, if required, the entire construction period and
                final inspection and testing.
              </li>
              <li>
                <ProjectStatus status={'Closeout'} /> - During this stage final
                payments are made and the contract is closed. A project will
                stay in this phase until the warranty period has ended. Closeout
                can also refer to cancelled projects which is indicated by the
                closeout status “Cancelled”.
              </li>
            </ul>
          </p>
        </div>
        <div className="line-divider"></div>
        <div className="ion-padding-horizontal">
          <h2>Project Cost</h2>
          <p>
            The amount of funding currently planned to be spent to fund a
            project through completion of all phases.
          </p>
        </div>
        <div className="line-divider"></div>
        <div className="ion-padding-horizontal">
          <h2>Contact</h2>
          <p>
            This person is the main point of contact for public inquiries about
            this project.
          </p>
        </div>
        <div className="line-divider"></div>
        <div className="ion-padding-horizontal ion-padding-bottom">
          <h2>Project ID</h2>
          <p>
            Used for internal tracking. This number is the unique identification
            number for each program or project on the Projects<sup>+</sup>
            application.
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: state => ({
    mode: getConfig().get('mode'),
  }),
  component: React.memo(TermsDefinitions),
});
