import React, { useState, useRef } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonHeader,
  getConfig,
  useIonViewDidEnter,
  IonModal,
} from '@ionic/react';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { setSegmentPreference } from '../../data/user/user.actions';
import ProjectList from '../../components/ProjectList/ProjectList';
import { menu, options, search } from 'ionicons/icons';
import * as selectors from '../../data/selectors';
import {
  setShowModal,
  setProjectSearchText,
} from '../../data/app-data/app-data.actions';
import { connect } from '../../data/connect';
import './ProjectsTab.scss';
import Preferences from '../Modals/Preferences';
import ProjectFilter from '../Modals/ProjectFilter';

const ProjectsTab = ({
  mode,
  setShowModal,
  allProjects,
  favoriteProjects,
  setProjectSearchText,
  segmentPreference,
  setSegmentPreference,
}) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Projects Tab',
      nameOverride: 'Projects Tab',
    });
  });
  const ios = mode === 'ios';
  const contentRef = useRef(null);
  const searchbarRef = useRef(null);
  const [showSearchbar, setShowSearchbar] = useState(false);

  const page = useRef(null);
  const preferencesModal = useRef(null);
  const projectFilterModal = useRef(null);
  console.log('All Projects', allProjects);
  return (
    <IonPage ref={page} id="projectsTab">
      <IonHeader translucent={true}>
        <IonToolbar>
          {!ios && !showSearchbar && (
            <IonButtons slot="start">
              <IonButton id="open-menu-modal">
                <IonIcon slot="icon-only" icon={menu}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {ios && (
            <IonButtons slot="start">
              <IonButton id="open-menu-modal">
                <IonIcon slot="icon-only" icon={menu}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {ios && <IonTitle>Projects</IonTitle>}
          {ios && (
            <IonButtons slot="end" style={{ marginRight: '7px' }}>
              <IonButton id="open-project-filter">Filter</IonButton>
            </IonButtons>
          )}
          {!ios && !showSearchbar && (
            <IonTitle style={{ paddingLeft: '0px' }}>Projects</IonTitle>
          )}
          {showSearchbar && (
            <IonSearchbar
              className="projects-searchbar"
              placeholder="Search"
              showCancelButton="always"
              onIonChange={e => setProjectSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
            ></IonSearchbar>
          )}
          {!ios && !showSearchbar && (
            <IonButtons slot="end">
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
              <IonButton id="open-project-filter">
                <IonIcon icon={options} slot="icon-only" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent
        fullscreen={true}
        ref={contentRef}
        forceOverscroll={false}
        scrollY={true}
      >
        <IonModal
          ref={preferencesModal}
          trigger="open-menu-modal"
          presentingElement={page.current}
        >
          <Preferences modalRef={preferencesModal} />
        </IonModal>
        <IonModal
          ref={projectFilterModal}
          trigger="open-project-filter"
          presentingElement={page.current}
        >
          <ProjectFilter modalRef={projectFilterModal} />
        </IonModal>
        {!ios && (
          <div style={{ padding: '10px 16px 0px 16px' }}>
            <IonSegment
              mode="ios"
              className="md-segment"
              value={segmentPreference}
              onIonChange={e => setSegmentPreference(e.detail.value)}
              style={{ maxWidth: '200px', margin: '0 auto' }}
            >
              <IonSegmentButton className="md-segment-button" value="all">
                All
              </IonSegmentButton>
              <IonSegmentButton className="md-segment-button" value="favorites">
                Favorites
              </IonSegmentButton>
            </IonSegment>
          </div>
        )}
        {ios && (
          <IonHeader collapse="condense" className="ion-no-border">
            <IonToolbar className="color-bg">
              <div className="projname-block">
                <h1>Projects</h1>
              </div>
              <IonButtons slot="end">
                <IonSegment
                  value={segmentPreference}
                  onIonChange={e => setSegmentPreference(e.detail.value)}
                  style={{ marginRight: '8px' }}
                >
                  <IonSegmentButton value="all">All</IonSegmentButton>
                  <IonSegmentButton value="favorites">
                    Favorites
                  </IonSegmentButton>
                </IonSegment>
              </IonButtons>
            </IonToolbar>
            <IonToolbar className="color-bg">
              <IonSearchbar
                type="search"
                placeholder="Search"
                showCancelButton="focus"
                onIonChange={e => setProjectSearchText(e.detail.value)}
                enterkeyhint="search"
                ref={searchbarRef}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>
        )}

        {contentRef.current !== null && (
          <>
            <ProjectList
              projects={allProjects}
              listType={segmentPreference}
              hide={segmentPreference === 'favorites'}
              contentRef={contentRef.current}
            />
            <ProjectList
              projects={favoriteProjects}
              listType={segmentPreference}
              hide={segmentPreference === 'all'}
              contentRef={contentRef.current}
              style={{ paddingBottom: '300px' }}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: state => ({
    mode: getConfig().get('mode'),
    showPreferences: state.user.showPreferences,
    segmentPreference: state.user.segmentPreference,
    allProjects: selectors.getSearchedProjects(state),
    favoriteProjects: selectors.getFavoritedProjects(state),
  }),
  mapDispatchToProps: {
    setProjectSearchText,
    setShowModal,
    setSegmentPreference,
  },
  component: React.memo(ProjectsTab),
});
