import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { shareOutline, shareSocial } from 'ionicons/icons';
import { share } from '../services/shareApi';

const ShareButton = ({ title, text, url, dialogTitle }) => {
  const handleShare = () => {
    share(title, text, url, dialogTitle);
  };
  return (
    <IonButton onClick={handleShare}>
      <IonIcon slot="icon-only" ios={shareOutline} md={shareSocial} />
    </IonButton>
  );
};

export default ShareButton;
