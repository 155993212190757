import React, { useEffect } from 'react';
import { IonButton, getConfig, IonIcon, isPlatform } from '@ionic/react';
import { connect } from '../data/connect';
import { addSubscription, removeSubscription } from '../data/user/user.actions';
import { notifications, notificationsOffOutline } from 'ionicons/icons';
import { hapticsImpact } from '../services/hapticsApi';

const NotificationToggle = ({
  projname,
  mode,
  projid,
  addSubscription,
  removeSubscription,
  isSubscribed,
  isFavorite,
}) => {
  useEffect(() => {
    if (isPlatform('capacitor')) {
      window['plugins'].OneSignal.getTags(function (tags) {
        console.log('TAGS: ', tags);
      });
    }
  }, []);

  const handleAddSubscription = async (id, name) => {
    await addSubscription(id);
    await hapticsImpact('medium');
    if (isPlatform('capacitor')) {
      await window['plugins'].OneSignal.sendTag(name, 2);
    }
  };

  const handleRemoveSubscription = async (id, name) => {
    await removeSubscription(id);
    await hapticsImpact('medium');
    if (isPlatform('capacitor')) {
      await window['plugins'].OneSignal.sendTag(name, 1);
    }
  };

  const toggleNotifications = () => {
    isSubscribed
      ? handleRemoveSubscription(
          projid,
          projname.replace(/\s+/g, '-').toLowerCase(),
        )
      : handleAddSubscription(
          projid,
          projname.replace(/\s+/g, '-').toLowerCase(),
        );
  };

  return (
    <IonButton
      fill="clear"
      onClick={toggleNotifications}
      className="favorite-toggle"
      color={
        isSubscribed
          ? mode === 'ios'
            ? 'primary'
            : 'white'
          : isFavorite
          ? 'danger'
          : 'primary'
      }
      mode="ios"
      disabled={!isFavorite}
    >
      <IonIcon
        slot="icon-only"
        icon={isSubscribed ? notifications : notificationsOffOutline}
      ></IonIcon>
    </IonButton>
  );
};

export default connect({
  mapStateToProps: state => ({
    mode: getConfig().get('mode'),
    enabledNotifications: state.user.enabledNotifications,
  }),
  mapDispatchToProps: {
    addSubscription,
    removeSubscription,
  },
  component: NotificationToggle,
});
