// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonGrid,
  IonRow,
  IonCol,
  // IonAccordionGroup,
  // IonAccordion,
  // IonItem,
  // IonLabel,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from '../../data/connect';
import AlertListItem from './AlertListItem';

const AlertList = ({ alerts, hide, listType }) => {
  const [activeAlerts, setActiveAlerts] = useState(alerts);
  useEffect(() => {
    const currentDate = new Date();
    activeAlerts.forEach(alert => {
      const startDate = new Date(alert.attributes.startDate);
      const endDate = new Date(alert.attributes.endDate);
      if (startDate < currentDate && endDate > currentDate) {
        return;
      } else {
        const activeAlerts = alerts.filter(a => a !== alert);
        setActiveAlerts(activeAlerts);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlerts]);

  // if (alertFeed.projectGroups.length === 0 && !hide) {
  if (alerts.length === 0 && !hide) {
    return (
      <>
        <div style={{ margin: '12px 16px 16px 16px', textAlign: 'left' }}>
          <h4>Nothing Found</h4>
          <p>
            {listType === 'all'
              ? "There aren't any alerts issued at this time"
              : "You're not following any projects. Follow a project to add its alerts here for easy access."}
          </p>
        </div>
      </>
    );
  } else if (activeAlerts.length === 0 && !hide) {
    return (
      <>
        <div style={{ margin: '12px 16px 16px 16px', textAlign: 'left' }}>
          <h4>Nothing Found</h4>
          <p>
            {listType === 'all'
              ? "There aren't any alerts issued at this time"
              : "You're not following any projects. Follow a project to add its alerts here for easy access."}
          </p>
        </div>
      </>
    );
  }

  return (
    <IonGrid style={hide ? { display: 'none' } : {}} className="card-grid">
      <IonRow>
        {activeAlerts.map(alert => (
          <IonCol size="12" sizeSm="6" key={alert.GlobalID}>
            <AlertListItem alert={alert.attributes} />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default connect({
  component: AlertList,
});
