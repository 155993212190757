import { Browser } from '@capacitor/browser';
import { isPlatform } from '@ionic/react';
import { hapticsImpact } from './hapticsApi';

export const openBrowser = async url => {
  if (isPlatform('capacitor')) {
    hapticsImpact('medium');
  }

  await Browser.open({
    url: url,
    toolbarColor: `#fff`,
    presentationStyle: `popover`,
  });
};
