import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { isPlatform, IonContent, IonPage, IonButton } from '@ionic/react';
import { Geolocation } from '@capacitor/geolocation';
import {
  setHasSeenTutorial,
  setLocationPermission,
} from '../../data/user/user.actions';
import { connect } from '../../data/connect';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Parallax } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './Tutorial.scss';
SwiperCore.use([Pagination, Parallax]);

const Tutorial = ({ setHasSeenTutorial, setLocationPermission }) => {
  const history = useHistory();

  const notificationPermissions = async () => {
    if (isPlatform('ios')) {
      window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(
        accepted => {
          if (accepted) {
            closeTutorial();
            window['plugins'].OneSignal.sendTags({
              project_updates: 1,
              public_meetings: 1,
              construction_notices: 1,
              traffic_alerts: 1,
              suggested_projects: 1,
              surveys_feedback: 1,
              new_features: 1,
            });
          } else if (!accepted) {
            closeTutorial();
          }
        },
      );
      return;
    }

    if (isPlatform('android')) {
      closeTutorial();
      window['plugins'].OneSignal.sendTags({
        project_updates: 1,
        public_meetings: 1,
        construction_notices: 1,
        traffic_alerts: 1,
        suggested_projects: 1,
        surveys_feedback: 1,
        new_features: 1,
      });

      return;
    }
  };

  const enablePermissions = async () => {
    if (isPlatform('capacitor')) {
      // is Native iOS or Android -- Check for permission
      const permissionStatus = await Geolocation.requestPermissions();
      setLocationPermission(permissionStatus.location);
      await notificationPermissions();
    } else {
      await closeTutorial();
    }
  };

  // Swiper
  const [showContinue, setShowContinue] = useState(true);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const slideRef = useRef(null);

  const handleSlideChangeStart = swiper => {
    if (swiper.isEnd) {
      setShowContinue(false);
    } else {
      setShowContinue(true);
    }
  };

  const handleContinue = () => {
    controlledSwiper.slideNext();
  };

  const closeTutorial = async () => {
    await setHasSeenTutorial(true);
    history.push('/tabs/projects', { direction: 'none' });
  };

  return (
    <IonPage id="tutorial-page">
      <IonContent fullscreen={true} slot="fixed" forceOverscroll={false}>
        <Swiper
          pagination={{ clickable: false }}
          style={{ height: '100%', width: '100%' }}
          width={window.innerWidth}
          spaceBetween={0}
          slidesPerView={1}
          parallax={true}
          ref={slideRef}
          onSlideChangeTransitionStart={swiper =>
            handleSlideChangeStart(swiper)
          }
          onSwiper={setControlledSwiper}
        >
          <SwiperSlide>
            <h2 className="slide-title" style={{ marginBottom: '40px' }}>
              Welcome to <b>Tampa CIP</b>
            </h2>

            <img src="assets/img/slide-1.png" alt="" className="slide-image" />
            <p style={{ marginTop: '10px' }}>
              The City of Tampa's mobile app for discovering, following and
              communicating about Capital Improvement Projects happening
              throughout the city.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="assets/img/t3-logo.png"
              alt=""
              className="slide-image-t3"
            />
            <p
              style={{
                textAlign: 'justify',
                marginTop: '10px',
                marginBottom: '20px',
              }}
            >
              Tampa invests millions of dollars into a variety of construction
              projects every year. These critical, long-term investments help
              support our economic vitality and promote livable neighborhoods.
              These projects can be in any one of a variety of phases, and
              completion can vary in a length of time from several months to
              several years. Regardless of their size or scope, these types of
              projects provide a tangible example of how we are Transforming
              Tampa’s Tomorrow.
            </p>
          </SwiperSlide>

          <SwiperSlide>
            <h2 className="slide-title">
              What <b>You</b> Can Do
            </h2>
            <img
              src="assets/img/slide-2.png"
              alt=""
              className="slide-image-3"
            />
            <p style={{ textAlign: 'justify' }}>
              Use the interactive map and/or project list to discover capital
              improvement projects impacting you. Once a project is selected,
              you’ll see specific information including key project details,
              it's currently issued alerts, and a point of contact to reach out
              to for more information.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h2 className="slide-title">
              We Just Need <b>Your Permission</b>
            </h2>
            <img
              src="assets/img/slide-4.png"
              alt=""
              className="slide-image-4"
            />
            <p style={{ textAlign: 'justify' }}>
              <b>Tampa CIP</b> works best with notifications and location
              services enabled. To get the best experience while using this app,
              please be sure to press <b> 'Allow'</b> when prompted.
            </p>
          </SwiperSlide>
        </Swiper>
        <div className="continue-container">
          {showContinue ? (
            <IonButton fill="solid" onClick={handleContinue}>
              Continue
            </IonButton>
          ) : (
            <IonButton fill="solid" onClick={enablePermissions}>
              Continue
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapDispatchToProps: {
    setHasSeenTutorial,
    setLocationPermission,
  },
  component: Tutorial,
});
