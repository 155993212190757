/* eslint-disable default-case */
import { StatusBar, Style } from '@capacitor/status-bar';
import { isPlatform } from '@ionic/react';

export const setStatusBarStyle = style => {
  if (!isPlatform('capacitor') || isPlatform('android')) {
    return;
  }
  switch (style) {
    case 'dark':
      StatusBar.setStyle({ style: Style.Dark });
      break;
    case 'light':
      StatusBar.setStyle({ style: Style.Light });
      break;
  }
};
