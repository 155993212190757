import React from 'react';
import { connect } from '../data/connect';
import { Redirect } from 'react-router';

const HomeOrTutorial = ({ hasSeenTutorial }) => {
  return hasSeenTutorial ? (
    <Redirect to="/tabs/projects" exact />
  ) : (
    <Redirect to="/tutorial" exact />
  );
};

export default connect({
  mapStateToProps: state => ({
    hasSeenTutorial: state.user.hasSeenTutorial,
  }),
  component: HomeOrTutorial,
});
