import React, { useEffect, useRef, useState } from 'react';
import { useLocation, withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonLabel,
  IonItem,
  getConfig,
  useIonViewDidEnter,
  IonList,
  IonModal,
  // IonButton,
  // NavContext,
  // IonButton,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { openBrowser } from '../../services/browserApi';
import { createParagraphs } from '../../services/textFormatters';
// import TimeSince from '../../components/TimeSince';
import ShareButton from '../../components/ShareButton';
import * as selectors from '../../data/selectors';
import { connect } from '../../data/connect';
import '../ProjectPage/ProjectPage.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDoNotEnter,
  faTrafficCone,
  faLocationArrow,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faPresentation,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons';
import TimeSince from '../../components/TimeSince';
import ImageThumbmails from '../../components/ImageThumbails';
import LightboxModal from '../Modals/LightboxModal/LightboxModal';
library.add(
  faDoNotEnter,
  faTrafficCone,
  faLocationArrow,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faPresentation,
  faUserPlus,
);

const AlertDetails = ({ alert, project, mode, alertAttachments }) => {
  const page = useRef(null);
  const location = useLocation();
  const ios = mode === 'ios';

  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Alert Details',
      nameOverride: 'Alert Details',
    });
  });
  const [images, setImages] = useState();
  const [documents, setDocuments] = useState();
  useEffect(() => {
    const attachments = alertAttachments.find(
      a => a.parentObjectId === alert.attributes.OBJECTID,
    );
    if (attachments) {
      const images = attachments.attachmentInfos.filter(a =>
        a.contentType.includes('image'),
      );
      const documents = attachments.attachmentInfos.filter(a =>
        a.contentType.includes('pdf'),
      );
      setImages(images);
      setDocuments(documents);
      console.log('All Attachments:', attachments.attachmentInfos);
      console.log('Images:', images);
      console.log('Documents:', documents);
    }
  }, [alertAttachments, alert]);

  // Images Lightbox
  const [showLightboxModal, setShowLightboxModal] = useState(false);
  const [lightboxContent, setLightboxContent] = useState();
  const [initialSlide, setInitialSlide] = useState(0);
  const openLightbox = (index, images) => {
    setLightboxContent(images);
    setInitialSlide(index);
    setShowLightboxModal(true);
  };
  const getAttachmentTitle = title => {
    return title.replace(/\.[^/.]+$/, '');
  };

  // const { navigate } = useContext(NavContext);

  // function goToProject(slug) {
  //   navigate('/tabs/projects/' + slug, 'forward');
  // }

  return (
    <IonPage id="project-page" ref={page}>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tabs/alerts"
              text={ios ? 'Alerts' : ''}
            ></IonBackButton>
          </IonButtons>
          <IonTitle>
            <FontAwesomeIcon
              className="alert-icon"
              color={`var(--ion-color-${alert.attributes.color})`}
              icon={['fas', `${alert.attributes.icon}`]}
              style={{
                fontSize: '19px',
                minWidth: '24px',
                marginRight: '8px',
              }}
            />
            {alert.attributes.title}
          </IonTitle>
          <IonButtons slot="end">
            <ShareButton
              title={alert.attributes.title}
              text="Check out this alert from Tampa CIP"
              url={`https://tampacip.app${location.pathname}`}
              dialogTitle="Share Alert"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {ios && (
          <IonHeader collapse="condense" className="ion-no-border">
            <IonToolbar className="color-bg">
              <div className="projname-block">
                <h1>
                  <FontAwesomeIcon
                    className="alert-icon"
                    color={`var(--ion-color-${alert.attributes.color})`}
                    icon={['fas', `${alert.attributes.icon}`]}
                    style={{
                      fontSize: '22px',
                      minWidth: '24px',
                      marginRight: '8px',
                    }}
                  />
                  {alert.attributes.title}
                </h1>
              </div>
            </IonToolbar>
          </IonHeader>
        )}

        <div style={{ margin: '0px 16px 0px 16px' }}>
          <TimeSince date={alert.attributes.startDate} actionText="Issued" />
        </div>
        <div style={{ margin: '10px 16px 22px 16px' }}>
          <p
            dangerouslySetInnerHTML={createParagraphs(
              alert.attributes.description,
            )}
            className="alert-description"
          />
        </div>
        {documents && (
          <>
            {documents.map(document => (
              <IonList inset key={document.id}>
                <IonItem
                  lines="none"
                  routerDirection="none"
                  onClick={() => openBrowser(document.url)}
                  detail={true}
                  target="_blank"
                >
                  <div slot="start">
                    <FontAwesomeIcon
                      color="var(--ion-color-primary)"
                      icon={['fas', `file-pdf`]}
                      style={{
                        fontSize: '22px',
                        minWidth: '30px',
                        marginTop: '4px',
                      }}
                    />
                  </div>
                  <IonLabel>
                    <h2
                      style={{
                        fontSize: '16px',
                        marginRight: '10px',
                      }}
                    >
                      {getAttachmentTitle(document.name)}
                    </h2>
                  </IonLabel>
                </IonItem>
              </IonList>
            ))}
          </>
        )}
        {images && (
          <>
            <div className="block project-images">
              {images.map((image, index) => (
                <ImageThumbmails
                  onClick={() => openLightbox(index, images)}
                  src={image.url}
                  key={index}
                  alt="Image"
                />
              ))}
            </div>

            <IonModal
              isOpen={showLightboxModal}
              onDidDismiss={() => setShowLightboxModal(false)}
              id="lightboxModal"
              presentingElement={page.current}
              // mode="md"
            >
              <LightboxModal
                onDismissModal={() => setShowLightboxModal(false)}
                initialSlide={initialSlide}
                images={lightboxContent}
              />
            </IonModal>
          </>
        )}
        {/* <div style={{ margin: '0px 16px 16px 16px' }}>
          <IonButton
            fill="solid"
            expand="block"
            onClick={() => goToProject(alert.attributes.projid)}
          >
            View Project Information
          </IonButton>
        </div> */}
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state, OwnProps) => ({
    alert: selectors.getAlert(state, OwnProps),
    alertAttachments: selectors.getAlertAttachments(state),
    project: selectors.getProject(state, OwnProps),
    mode: getConfig().get('mode'),
  }),
  component: withRouter(AlertDetails),
});
