import React, { useState, useRef } from 'react';

import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  // IonSegment,
  // IonSegmentButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonHeader,
  getConfig,
  // IonSpinner,
  useIonViewDidEnter,
} from '@ionic/react';
import { menu, search } from 'ionicons/icons';
import * as selectors from '../../data/selectors';
import { connect } from '../../data/connect';
import {
  setAlertSearchText,
  setShowModal,
} from '../../data/app-data/app-data.actions';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import './AlertsTab.scss';
import AlertsList from '../../components/AlertList/AlertsList';

// const AlertList = lazy(() => {
//   return Promise.all([
//     import('../../components/AlertList/AlertsList'),
//     new Promise(resolve => setTimeout(resolve, 300)),
//   ]).then(([moduleExports]) => moduleExports);
// });

const AlertsTab = ({
  alerts,
  alertFeed,
  alertFeedFollowing,
  setAlertSearchText,
  mode,
  darkMode,
  setShowModal,
}) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Alerts Tab',
      nameOverride: 'Alerts Tab',
    });
  });
  const pageRef = useRef(null);
  // const [segment, setSegment] = useState('all');
  const segment = 'all';
  const [showSearchbar, setShowSearchbar] = useState(false);
  const ios = mode === 'ios';

  const searchbarRefAlerts = useRef(null);
  const contentRefAlerts = useRef(null);

  return (
    <IonPage ref={pageRef} id="alertsTab">
      <IonHeader translucent={true}>
        <IonToolbar>
          {!ios && !showSearchbar && (
            <IonButtons slot="start">
              <IonButton
                onClick={() => setShowModal({ isOpen: true, page: 'menu' })}
              >
                <IonIcon slot="icon-only" icon={menu}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {ios && (
            <IonButtons slot="start">
              <IonButton
                onClick={() => setShowModal({ isOpen: true, page: 'menu' })}
              >
                <IonIcon slot="icon-only" icon={menu}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {ios && <IonTitle>Alerts</IonTitle>}
          {ios && (
            <IonButtons slot="end" style={{ marginRight: '7px' }}>
              {/* <IonButton
                disabled={alerts.length === 0}
                onClick={() =>
                  setShowModal({ isOpen: true, page: 'alert-filter' })
                }
              >
                Filter
              </IonButton> */}
            </IonButtons>
          )}
          {!ios && !showSearchbar && (
            <IonTitle style={{ paddingLeft: '0px' }}>Alerts</IonTitle>
          )}
          {showSearchbar && (
            <IonSearchbar
              className="projects-searchbar"
              placeholder="Search"
              showCancelButton="always"
              onIonChange={e => setAlertSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
            ></IonSearchbar>
          )}
          {!ios && !showSearchbar && (
            <IonButtons slot="end">
              <IonButton
                onClick={() => setShowSearchbar(true)}
                disabled={alertFeed.projectGroups.length === 0}
              >
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
              {/* <IonButton
                disabled={alertFeed.projectGroups.length === 0}
                onClick={() =>
                  setShowModal({ isOpen: true, page: 'alert-filter' })
                }
              >
                <IonIcon icon={options} slot="icon-only" />
              </IonButton> */}
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true} ref={contentRefAlerts}>
        {/* {!ios && (
          <div style={{ padding: '10px 16px 0px 16px' }}>
            <IonSegment
              mode="ios"
              className="md-segment"
              value={segment}
              onIonChange={e => setSegment(e.detail.value)}
              style={{ maxWidth: '200px', margin: '0 auto' }}
            >
              <IonSegmentButton className="md-segment-button" value="all">
                All
              </IonSegmentButton>
              <IonSegmentButton className="md-segment-button" value="following">
                Following
              </IonSegmentButton>
            </IonSegment>
          </div>
        )} */}
        {ios && (
          <IonHeader collapse="condense" className="ion-no-border">
            <IonToolbar className="color-bg">
              {/* <IonTitle color={darkMode ? 'white' : 'black'} size="large">
                Alerts
              </IonTitle> */}
              <div className="projname-block">
                <h1>Alerts</h1>
              </div>
              {/* <IonButtons slot="end">
                <IonSegment
                  value={segment}
                  onIonChange={e => setSegment(e.detail.value)}
                  style={{ marginRight: '8px' }}
                >
                  <IonSegmentButton value="all">All</IonSegmentButton>
                  <IonSegmentButton value="following">
                    Following
                  </IonSegmentButton>
                </IonSegment>
              </IonButtons> */}
            </IonToolbar>
            <IonToolbar className="color-bg">
              <IonSearchbar
                placeholder="Search"
                showCancelButton="focus"
                onIonCancel={() => setShowSearchbar(false)}
                onIonChange={e => setAlertSearchText(e.detail.value)}
                enterkeyhint="search"
                ref={searchbarRefAlerts}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>
        )}

        <AlertsList
          alerts={alerts}
          alertFeed={alertFeed}
          hide={segment === 'following'}
          listType={segment}
        />

        <AlertsList
          alerts={alerts}
          alertFeed={alertFeedFollowing}
          hide={segment === 'all'}
          listType={segment}
        />
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: state => ({
    alerts: state.data.alerts,
    alertFeed: selectors.getSearchedAlerts(state),
    alertFeedFollowing: selectors.getFolliwngAlerts(state),
    mode: getConfig().get('mode'),
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    setAlertSearchText,
    setShowModal,
  },
  component: React.memo(AlertsTab),
});
