/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import {
  IonApp,
  setupIonicReact,
  IonRouterOutlet,
  isPlatform,
  NavContext,
} from '@ionic/react';
// Context & State
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import {
  loadAppData,
  setNetworkStatus,
} from './data/app-data/app-data.actions';
import { loadUserData, setDarkMode } from './data/user/user.actions';
// Services
import { Network } from '@capacitor/network';
import { initFirebase, setUserId } from './services/Firebase';
import DeepLinkListener from './services/DeepLinkListener';
import { setStatusBarStyle } from './services/statusBarApi';
// Pages/Tabs
import MainTabs from './components/MainTabs';
// Styling
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/variables.css';
import './theme/Main.scss';
// Font Awesome Library
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import ExploreTabPortal from './pages/ExploreTab/ExploreTabPortal';
import Tutorial from './pages/Tutorial/Tutorial';
import StoreRedirect from './pages/StoreRedirect/StoreRedirect';
import HomeOrTutorial from './components/HomeOrTutorial';
library.add(fas, fal);

// Ionic Setup Config
setupIonicReact({
  swipeBackEnabled: false,
  statusTap: false,
});

const App = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

const IonicApp = ({
  projects,
  loadAppData,
  loadUserData,
  themeSetting,
  setDarkMode,
  darkMode,
  setNetworkStatus,
}) => {
  const routerRef = useRef(null);
  const { navigate } = useContext(NavContext);

  useEffect(() => {
    // Load User Data
    loadUserData();
    // Check Network Status and React to Changes
    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      if (status.connected) {
        setNetworkStatus(false);
        // console.log('initial network status: ONLINE (load app data)');
        loadAppData(true);
      } else if (!status.connected) {
        setNetworkStatus(true);
        // console.log('initial network status: OFFLINE (load app data)');
        loadAppData(false);
      }
      Network.addListener('networkStatusChange', status => {
        if (status.connected) {
          // setNetworkStatus(false);
          loadAppData(true);
          // console.log('network status change: NOW ONLINE');
        } else if (!status.connected) {
          setNetworkStatus(true);
          // console.log('network status change: NOW OFFLINE');
          loadAppData(false);
        }
      });
    };
    checkNetworkStatus();
    // Initiralize Firebase Web
    initFirebase();
    // Initialize OneSignal
    const initializeOneSignal = async () => {
      try {
        if (isPlatform('capacitor')) {
          window['plugins'].OneSignal.setAppId(
            'e8e79409-44a4-4bdf-af33-d3224c3d944a',
          );
          var notificationOpenedCallback = function (notificationData) {
            const launchUrl = notificationData.notification.launchURL;
            const slug = launchUrl.split('.app').pop();
            // console.log('notificationData: ', notificationData);
            navigate(slug, 'none');
          };
          window['plugins'].OneSignal.setNotificationOpenedHandler(
            notificationOpenedCallback,
          );
          window['plugins'].OneSignal.addSubscriptionObserver(function (state) {
            if (!state.from.subscribed && state.to.subscribed) {
              // console.log('Subscribed for OneSignal push notifications!');
              setUserId(JSON.stringify(state.to.userId));
            }
            // console.log(
            //   'Push Subscription state changed: ' + JSON.stringify(state),
            // );
          });
          window['plugins'].OneSignal.addPermissionObserver(function (state) {
            // console.log('Notification permission state changed: ' + state);
            // console.log('Notification permission status: ' + state);
          });
        }
      } catch (e) {
        console.log('Initalize OneSignal ERROR', e);
      }
    };
    initializeOneSignal();
  }, []);

  // App theme state
  const [prefersDarkTheme, setPrefersDarkTheme] = useState();
  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    toggleDarkTheme(prefersDark.matches);
    prefersDark.addListener(mediaQuery => toggleDarkTheme(mediaQuery.matches));
    function toggleDarkTheme(shouldAdd) {
      setPrefersDarkTheme(shouldAdd);
    }
  }, []);
  useEffect(() => {
    if (themeSetting === 'light') {
      setDarkMode(false);
      setStatusBarStyle('light');
      return;
    }
    if (themeSetting === 'dark') {
      setDarkMode(true);
      setStatusBarStyle('dark');
      return;
    }
    if (themeSetting === 'device' && prefersDarkTheme) {
      setDarkMode(true);
      setStatusBarStyle('dark');
    } else if (themeSetting === 'device' && !prefersDarkTheme) {
      setDarkMode(false);
      setStatusBarStyle('light');
    }
  }, [themeSetting, prefersDarkTheme]);

  return projects.length === 0 ? (
    <div></div>
  ) : (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        <DeepLinkListener />
        <IonRouterOutlet id="main" ref={routerRef}>
          <Route path="/tabs" component={MainTabs} />
          <Route path="/tutorial" component={Tutorial} />
          <Route path="/" component={HomeOrTutorial} exact />
          <Route path="/download-the-app" component={StoreRedirect} exact />
        </IonRouterOutlet>
        <ExploreTabPortal />
        {/* <Modals routerRef={routerRef} /> */}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const IonicAppConnected = connect({
  mapStateToProps: state => ({
    projects: state.data.projects,
    darkMode: state.user.darkMode,
    themeSetting: state.user.themeSetting,
  }),
  mapDispatchToProps: {
    loadAppData,
    loadUserData,
    setDarkMode,
    setNetworkStatus,
  },
  component: IonicApp,
});
