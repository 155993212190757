import React from 'react';
// import { Virtuoso } from 'react-virtuoso';
import { addFavorite, removeFavorite } from '../../data/user/user.actions';
import ProjectCard from './ProjectCard';
import { connect } from '../../data/connect';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
const ProjectListCIP = ({
  hide,
  listType,
  projects,
  favoritedProjects,
  contentRef,
}) => {
  // const virtuosoRef = useRef(null);
  // const [isScrolling, setIsScrolling] = useState(false);

  // const handleStatusTap = useCallback(
  //   event => {
  //     if (isScrolling) {
  //     } else if (!isScrolling) {
  //       virtuosoRef.current.scrollTo({
  //         top: 0,
  //         left: 0,
  //         behavior: 'smooth',
  //       });
  //     }
  //   },
  //   [isScrolling],
  // );

  // useEffect(() => {
  //   window.addEventListener('statusTap', handleStatusTap);
  //   return () => {
  //     window.removeEventListener('statusTap', handleStatusTap);
  //   };
  // }, [handleStatusTap]);
  //
  //   const handleTopState = isTop => {
  //     if (isTop) {
  //       // console.log(isTop, 'true');
  //       contentRef.scrollToTop(200);
  //     } else {
  //       // console.log(isTop, 'false');
  //       contentRef.scrollToBottom(400);
  //     }
  //   };

  if (projects.filteredProjects.length === 0 && !hide) {
    return (
      <>
        <div style={{ margin: '12px 16px 16px 16px', textAlign: 'left' }}>
          <h4>{listType === 'all' ? 'Nothing Found' : 'Nothing Found'}</h4>
          <p>
            {listType === 'all'
              ? "Uh oh. We couldn't find any projects that match your search. Please try again."
              : "You're not following any projects. Follow a project to save it here for easy access."}
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <IonGrid style={hide ? { display: 'none' } : {}} className="">
        <IonRow>
          {projects.filteredProjects.map(project => {
            if (project.projid.startsWith('WTR-20-0005')) {
              // eslint-disable-next-line array-callback-return
              return;
            }

            return (
              <IonCol size="12" sizeSm="6" key={project.projid}>
                <ProjectCard
                  projid={project.projid}
                  projname={project.projname}
                  projphase={project.projphase}
                  projtype={project.projtype}
                  EditDate={project.EditDate}
                  isFavorite={favoritedProjects.indexOf(project.projid) > -1}
                />
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </>
  );
};
export default connect({
  mapStateToProps: state => ({
    favoritedProjects: state.user.favoritedProjects,
  }),
  mapDispatchToProps: {
    addFavorite,
    removeFavorite,
  },
  component: ProjectListCIP,
});
