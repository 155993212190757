import { Preferences } from '@capacitor/preferences';

const valerinDatabaseOffline = 'assets/data/valerinDatabaseOffline.json';
const valerinDatabaseAttachmentsOffline =
  'assets/data/valerinDatabaseAttachmentsOffline.json';
const cityDatabaseOffline = 'assets/data/cipDatabaseOffline.json';
const alertsDatabaseOffline = 'assets/data/alertsDatabaseOffline.json';
const alertAttachmentsDatabaseOffline =
  'assets/data/alertsAttachmentsDatabaseOffline.json';
const valerinDatabase =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/City_of_Tampa_-_Valerin_Projects_(Public)/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=';
const valerinDatabaseAttachments =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/City_of_Tampa_-_Valerin_Projects_(Public)/FeatureServer/0/queryAttachments?objectIds=&globalIds=&definitionExpression=1%3D1&attachmentsDefinitionExpression=&attachmentTypes=&size=&keywords=&resultOffset=&resultRecordCount=&returnUrl=true&returnCountOnly=false&f=pjson&token=';
const cityDatabase =
  'https://arcgis.tampagov.net/arcgis/rest/services/CapitalProjects/CapitalProjects/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=json';
const alertsDatabase =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/City_of_Tampa_-_Valerin_Alerts_(Public)/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=json&token=';
const alertAttachmentsDatabase =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/City_of_Tampa_-_Valerin_Alerts_(Public)/FeatureServer/0/queryAttachments?objectIds=1&globalIds=&definitionExpression=&attachmentsDefinitionExpression=&attachmentTypes=&size=&keywords=&resultOffset=&resultRecordCount=&returnUrl=true&f=json&token=';

// const firebaseDataURL = 'assets/data/firebaseOffline.json';
// const firebaseDataURL = 'https://tampa-cip-default-rtdb.firebaseio.com/.json';

// Storage Keys
const FILTERED_PROJECT_PHASES = 'filteredProjectPhase';
const FILTERED_PROJECT_CATEGORIES = 'filteredProjectCategories';
const FILTERED_ALERT_CATEGORIES = 'filteredAlertategories';
const BACKUP_APP_DATA = 'backupData';

function update(target, source) {
  Object.entries(source).forEach(([key, value]) => {
    if ((!value && typeof value === 'object') || value === ' ' || value === 0) {
      return;
    }
    if (value && typeof value === 'object') {
      update(
        (target[key] = target[key] || (Array.isArray(value) ? [] : {})),
        value,
      );
    } else if (target[key] !== value) {
      target[key] = value;
    }
  });
}
export const getAppData = async network => {
  const response = await Promise.all([
    // fetch(valerinDatabase),
    fetch(network ? valerinDatabase : valerinDatabaseOffline),
    fetch(
      network ? valerinDatabaseAttachments : valerinDatabaseAttachmentsOffline,
    ),
    fetch(network ? cityDatabase : cityDatabaseOffline),
    // fetch(firebaseDataURL),
    fetch(network ? alertsDatabase : alertsDatabaseOffline),
    fetch(network ? alertAttachmentsDatabase : alertAttachmentsDatabaseOffline),
    Preferences.get({ key: FILTERED_PROJECT_PHASES }),
    Preferences.get({ key: FILTERED_PROJECT_CATEGORIES }),
    Preferences.get({ key: FILTERED_ALERT_CATEGORIES }),
  ]);
  // Valerin ArcGIS database
  const valerinData = await response[0].json();
  const valerinProjects = valerinData.features;
  const valerinAttachmentsData = await response[1].json();
  const projectAttachments = valerinAttachmentsData.attachmentGroups;
  // City of Tampa ArcGIS database
  const cityData = await response[2].json();
  const cityProjects = cityData.features;
  const cityProjectsFiltered = cityProjects.filter(
    p =>
      // Tampa Water Main Improvements
      p.attributes.projid.includes('WTR-20-0005.') |
      // Palma Ceia Water Main Improvements
      // p.attributes.projid.includes('1001842') |
      p.attributes.GlobalID.includes('{C6DF64A4-8EDE-44A5-99BC-305082C622BA}') |
      // Cypress Street Outfall
      p.attributes.projid.includes('1001018') |
      // Channelside Drive Improvements
      p.attributes.projid.includes('P&R22011') |
      // Lower Peninsula Stormwater
      p.attributes.projid.includes('1000750')|
      // Seminole Heights Stormwater
      p.attributes.projid.includes('1001151') ,
      // Seminole Heights Stormwater
      // p.attributes.projid.includes('WTR-19-0004'),
  );
  // Overwrite data in the City database with data from the Valerin database
  var updatedProjects = cityProjectsFiltered,
    newProjects = valerinProjects,
    map = new Map(newProjects.map(p => [p.attributes.projid, p]));
  updatedProjects.forEach(
    p =>
      map.has(p.attributes.projid) && update(p, map.get(p.attributes.projid)),
  );
  // Add projects not found in City
  var projects = updatedProjects,
    missingProjects = valerinProjects,
    hash = Object.create(null);

  projects.forEach(function (p) {
    hash[p.attributes.projid] = true;
  });
  missingProjects.forEach(function (p) {
    hash[p.attributes.projid] || projects.push(p);
  });

  // Add in additional data from firebase database - highlights, benefits, faqs
  // const firebaseData = await response[3];
  // const firebaseProjects = firebaseData.projects;
  // var projects = allProjects,
  //   newFirebaseProjects = firebaseProjects,
  //   map2 = new Map(newFirebaseProjects.map(p => [p.attributes.projid, p]));
  // projects.forEach(
  //   p =>
  //     map2.has(p.attributes.projid) && update(p, map2.get(p.attributes.projid)),
  // );
  // console.log('Final Projects', projects);
  // Get all project categories for filter
  const projectCategories = projects
    .reduce((all, project) => all.concat(project.attributes.projtype), [])
    .filter(
      (projectCategoryName, index, array) =>
        array.indexOf(projectCategoryName) === index,
    )
    .sort();
  // Get all project phases for filter
  const projectPhases = projects
    .reduce((all, project) => all.concat(project.attributes.projphase), [])
    .filter(
      (projectPhaseName, index, array) =>
        array.indexOf(projectPhaseName) === index,
    )
    .sort();
  // Get alerts from server
  const alertsResponse = await response[3].json();
  const alerts = alertsResponse.features;
  // Get alert attachments from server
  const alertAttachmentsResponse = await response[4].json();
  const alertAttachments = alertAttachmentsResponse.attachmentGroups;
  // Get all alert categories
  const alertCategories = alerts
    .reduce((all, alert) => all.concat(alert.attributes.category), [])
    .filter(
      (alerCategoryName, index, array) =>
        array.indexOf(alerCategoryName) === index,
    )
    .sort();
  // RETRIEVE STORED FILTER STATE
  const filteredProjectPhases = (await JSON.parse(response[5].value)) || [
    ...projectPhases,
  ];
  const filteredProjectCategories = (await JSON.parse(response[6].value)) || [
    ...projectCategories,
  ];
  const filteredAlertCategories = (await JSON.parse(response[7].value)) || [
    ...alertCategories,
  ];
  const data = {
    projects,
    projectAttachments,
    projectPhases,
    projectCategories,
    alerts,
    alertAttachments,
    alertCategories,
    filteredProjectPhases,
    filteredProjectCategories,
    filteredAlertCategories,
  };
  return data;
};
//-------- STATE STORAGE - DATA -------- //
export const getBackupAppData = async () => {
  const response = await Promise.all([
    Preferences.get({ key: BACKUP_APP_DATA }),
  ]);
  return (await JSON.parse(response[0].value)) || undefined;
};
export const setBackupAppData = async data => {
  await Preferences.set({ key: BACKUP_APP_DATA, value: JSON.stringify(data) });
};
export const setFilteredProjectPhasesData = async filteredProjectPhases => {
  await Preferences.set({
    key: FILTERED_PROJECT_PHASES,
    value: JSON.stringify(filteredProjectPhases),
  });
};
export const setFilteredProjectCategoriesData =
  async filteredProjectCategories => {
    await Preferences.set({
      key: FILTERED_PROJECT_CATEGORIES,
      value: JSON.stringify(filteredProjectCategories),
    });
  };
export const setFilteredAlertCategoriesData = async filteredAlertCategories => {
  await Preferences.set({
    key: FILTERED_ALERT_CATEGORIES,
    value: JSON.stringify(filteredAlertCategories),
  });
};

//-------- USER DATA & STORED STATE -------- //
const TEXT_SIZE = 'textSize';
const THEME_SETTING = 'themeSetting';
const HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
const FAVORITED_PROJECTS = 'favoriteProjects';
const SUBSCRIBED_PROJECTS = 'subscribedProjects';
const SEGMENT_PREFERENCE = 'segmentPreference';
const ENABLED_NOTIFICATIONS = 'enabledNotifications';
export const getUserData = async () => {
  const response = await Promise.all([
    Preferences.get({ key: TEXT_SIZE }),
    Preferences.get({ key: THEME_SETTING }),
    Preferences.get({ key: HAS_SEEN_TUTORIAL }),
    Preferences.get({ key: SEGMENT_PREFERENCE }),
    Preferences.get({ key: FAVORITED_PROJECTS }),
    Preferences.get({ key: SUBSCRIBED_PROJECTS }),
    Preferences.get({ key: ENABLED_NOTIFICATIONS }),
  ]);
  const textSize = (await JSON.parse(response[0].value)) || 'medium';
  const themeSetting = (await JSON.parse(response[1].value)) || 'light';
  const hasSeenTutorial = (await response[2].value) === 'true';
  const segmentPreference = (await JSON.parse(response[3].value)) || 'all';
  const favoritedProjects = (await JSON.parse(response[4].value)) || [];
  const subscribedProjects = (await JSON.parse(response[5].value)) || [];
  const enabledNotifications = (await JSON.parse(response[6].value)) || [
    'project_updates',
    'public_meetings',
    'construction_notices',
    'traffic_alerts',
    'suggested_projects',
    'surveys_feedback',
    'new_features',
  ];
  const data = {
    textSize,
    themeSetting,
    hasSeenTutorial,
    segmentPreference,
    favoritedProjects,
    subscribedProjects,
    enabledNotifications,
  };
  return data;
};

export const setHasSeenTutorialData = async hasSeenTutorial => {
  await Preferences.set({
    key: HAS_SEEN_TUTORIAL,
    value: JSON.stringify(hasSeenTutorial),
  });
};
export const setDarkModeData = async themeSetting => {
  await Preferences.set({
    key: THEME_SETTING,
    value: JSON.stringify(themeSetting),
  });
};
export const setSegmentPreferenceData = async segmentPreference => {
  await Preferences.set({
    key: SEGMENT_PREFERENCE,
    value: JSON.stringify(segmentPreference),
  });
};
export const setFavoritedProjectsData = async favoritedProjects => {
  await Preferences.set({
    key: FAVORITED_PROJECTS,
    value: JSON.stringify(favoritedProjects),
  });
};
export const setSubscribedProjectsData = async subscribedProjects => {
  await Preferences.set({
    key: SUBSCRIBED_PROJECTS,
    value: JSON.stringify(subscribedProjects),
  });
};
export const setEnabledNotificationsData = async enabledNotifications => {
  await Preferences.set({
    key: ENABLED_NOTIFICATIONS,
    value: JSON.stringify(enabledNotifications),
  });
};

// export const setTextSizeData = async textSize => {
//   await Preferences.set({ key: TEXT_SIZE, value: JSON.stringify(textSize) });
// };
// export const setUsernameData = async username => {
//   if (!username) {
//     await Storage.remove({ key: USERNAME });
//   } else {
//     await Preferences.set({ key: USERNAME, value: username });
//   }
// };
//
// export const setUseremailData = async useremail => {
//   if (!useremail) {
//     await Storage.remove({ key: USEREMAIL });
//   } else {
//     await Preferences.set({ key: USEREMAIL, value: useremail });
//   }
// };
