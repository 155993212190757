/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useContext } from 'react';
import {
  isPlatform,
  IonButton,
  IonSpinner,
  useIonAlert,
  NavContext,
  IonIcon,
} from '@ionic/react';
import { OpenNativeSettings } from '@ionic-native/open-native-settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hapticsImpact } from '../../services/hapticsApi';
import { Geolocation } from '@capacitor/geolocation';
import { connect } from '../../data/connect';
import { wifiOutline } from 'ionicons/icons';
import { loadModules } from 'esri-loader';
import './ExploreTab.scss';

const ArcGisMap = ({ darkMode, network }) => {
  const mapDiv = useRef(null);
  const [present] = useIonAlert();
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const isCapacitor = isPlatform('capacitor');
  const { navigate } = useContext(NavContext);
  const [view, setView] = useState(null);
  const [webmap, setWebmap] = useState(null);

  useEffect(() => {
    if (mapDiv.current && !network) {
      setIsMapLoading(false);
    }
    if (mapDiv.current && network) {
      setIsMapLoading(true);
      loadModules(
        [
          'esri/WebMap',
          'esri/views/MapView',
          'esri/widgets/Home',
          'esri/config',
          'esri/Graphic',
        ],
        {
          css: true,
        },
      )
        .then(([WebMap, MapView, Home, esriConfig, Graphic]) => {
          esriConfig.apiKey =
            'AAPKac56007fe9da4af5868d0b5c86e5b678oi9d-MxSKZQiAyqveBbz-ivDfWm4kwxIdvZMSbyNPSbCywUOCFWmhjcz5ojCDwTV';

          const webmap = new WebMap({
            portalItem: {
              id: '49ec77b4ce304ce590689501bb08fd1a',
            },
          });
          const view = new MapView({
            map: webmap,
            container: mapDiv.current,
            center: [-82.489502, 27.966963],
            zoom: 10,
            highlightOptions: {
              color: 'red',
              haloColor: '#0072bc',
              haloOpacity: 0.2,
              fillOpacity: 0,
            },
            constraints: {
              geometry: {
                type: 'extent',
                xmin: -82.904591,
                ymin: 27.611128,
                xmax: -81.991352,
                ymax: 28.326728,
              },
              minZoom: 10,
              maxZoom: 18,
            },
            navigation: {
              mouseWheelZoomEnabled: true,
              browserTouchPanEnabled: true,
            },
            popup: {
              overwriteActions: false,
              visibleElements: {
                closeButton: true,
                featureNavigation: false,
              },
              spinnerEnabled: true,
              dockEnabled: false,
              dockOptions: {
                buttonEnabled: false,
                breakpoint: false,
                position: 'bottom-center',
              },
              alignment: 'top-center',
              collapseEnabled: false,
            },
          });

          webmap
            .load()
            .then(function () {
              // Load Basemap to initialize layers
              return webmap.basemap.load();
            })
            .then(function () {
              // grab all the layers and load them
              const allLayers = webmap.allLayers;
              const promises = allLayers.map(function (layer) {
                return layer.load();
              });
              return Promise.all(promises.toArray());
            })
            .then(function (layers) {
              // each layer load promise resolves with the layer
              const pointLayer = layers[1];
              view.whenLayerView(pointLayer).then(function (layerView) {
                layerView.watch('updating', function (value) {
                  // once the layer view finishes updating
                  if (!value) {
                    setIsMapLoading(false);
                  }
                });
              });
              pointLayer.popupTemplate = {
                title: `{projname}`,
                outFields: ['projid'],
                actions: [
                  {
                    id: 'view-project',
                    title: 'View Project',
                    className: 'esri-icon-description',
                  },
                ],
              };
              if (darkMode) {
                webmap.basemap = 'arcgis-dark-gray';
              }
            });
          setView(view);
          setWebmap(webmap);
          // Zoom to selected feature and close tooltip sheet if open
          view.popup.watch('selectedFeature', graphic => {
            if (graphic) {
              hapticsImpact('medium');
              view.goTo(graphic.geometry);
            }
          });
          // View ready
          view.when(() => {
            const { popup } = view;
            popup.viewModel.on('trigger-action', event => {
              hapticsImpact('medium');
              const { attributes } = popup.viewModel.selectedFeature;
              var projid = attributes.projid;
              if (event.action.id === 'view-project') {
                navigate(`/tabs/projects/${projid}`, 'forward');
              }
            });
          });
          // Add Home Button
          const homeBtn = new Home({
            view,
          });
          view.ui.add(homeBtn, {
            position: 'top-right',
          });
          // Add Location Button
          view.ui.add(document.getElementById('actions'), 'top-right');
          const addBtn = document.getElementById('add');
          addBtn.addEventListener('click', () => {
            getLocation();
          });
          // Remove Attribution
          view.ui.remove('attribution');

          // Get User Location
          async function getLocation() {
            setIsLocationLoading(true);
            hapticsImpact('medium');
            const markerSymbol = {
              type: 'simple-marker',
              color: '#0072bc',
              size: '16px',
              outline: {
                color: [255, 255, 255],
                width: 2,
              },
            };

            try {
              view.graphics.removeAll();
              const position = await Geolocation.getCurrentPosition({});
              const point = {
                type: 'point',
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
              };
              const pointGraphic = new Graphic({
                geometry: point,
                symbol: markerSymbol,
              });
              view.graphics.add(pointGraphic);
              view.goTo(pointGraphic);
            } catch (e) {
              // console.log(e);
              // console.log('catch');
              if (isCapacitor) {
                const permission = await Geolocation.checkPermissions();
                if (permission.location === 'granted') {
                  present({
                    cssClass: 'my-css',
                    header: 'Location Error',
                    message: 'Could not retrieve location. Please try again.',
                    buttons: [
                      { text: 'Ok', handler: d => console.log('ok pressed') },
                    ],
                    // onDidDismiss: e => console.log('did dismiss'),
                  });
                  return;
                }
                if (permission.location === 'denied') {
                  present({
                    cssClass: 'my-css',
                    header: 'Location Error',
                    subHeader:
                      'Tampa CIP works best with Location Services turned on.',
                    buttons: [
                      {
                        text: 'Turn On in Settings',
                        handler: () =>
                          OpenNativeSettings.open(
                            'application_details',
                            function () {
                              // console.log('opened location settings');
                            },
                            function () {
                              // console.log('failed to open location settings');
                            },
                          ),
                      },
                      {
                        text: 'Keep Location Services Off',
                        handler: d => {
                          // console.log('Keep Location Services Off pressed'),
                        },
                      },
                    ],
                    onDidDismiss: e => {
                      // console.log('Location settings alert dismissed'),
                    },
                  });
                  return;
                }
              }
              if (!isCapacitor) {
                if (e.code === 1) {
                  present({
                    cssClass: 'my-css',
                    header: 'Location Permission Blocked',
                    subHeader:
                      'Enable location permissions in the browser to see your position.',
                    buttons: [
                      {
                        text: 'Ok Got It',
                      },
                    ],
                  });
                  return;
                }

                if (e.code === 2) {
                  present({
                    cssClass: 'my-css',
                    header: 'Location Not Available',
                    subHeader:
                      "Location services aren't available on this device",
                    buttons: [
                      {
                        text: 'Ok Got It',
                      },
                    ],
                  });
                  return;
                }
                if (e.code === 3) {
                  present({
                    cssClass: 'my-css',
                    header: 'Location Error',
                    subHeader: 'Location request timed out. Please try again.',
                    buttons: [
                      {
                        text: 'Ok Got It',
                      },
                    ],
                  });
                  return;
                }
                return;
              }
            } finally {
              setIsLocationLoading(false);
            }
          }
        })
        .catch(err => {
          // handle any script or module loading errors
          console.error('EsriLoader ERROR: ', err);
        });
    }
    return () => {
      if (view) {
        view.destroy();
      }
    };
  }, [network]);

  useEffect(() => {
    if (!view || !webmap) {
      return;
    }
    if (darkMode) {
      view.map.basemap = 'arcgis-dark-gray';
    } else if (!darkMode) {
      view.map.basemap = 'arcgis-topographic';
    }
  }, [view, darkMode]);

  return (
    <>
      {isMapLoading && (
        <div className="network-error ion-padding ion-text-center">
          <IonSpinner name="lines" color={darkMode ? 'white' : 'primary'} />
          <h1 style={{ fontSize: '18px' }}>Map Loading</h1>
          {/* <p>Hang tight. It's almost ready.</p> */}
        </div>
      )}

      <div className="safe-area-header"></div>
      <div
        ref={mapDiv}
        className="mapDiv"
        style={network ? {} : { display: 'none' }}
      ></div>
      <div
        id="actions"
        className="esri-widget no-resizing"
        style={network ? {} : { display: 'none' }}
      >
        <IonButton fill="solid" size="small" id="add" color="white">
          {isLocationLoading ? (
            <IonSpinner
              color="primary"
              name="lines"
              className="cip-spinner"
              style={{ fontSize: '40px' }}
            />
          ) : (
            <FontAwesomeIcon
              color="#0072bc"
              icon={['fal', 'location']}
              style={{ fontSize: '18px' }}
            />
          )}
        </IonButton>
      </div>

      {!network && (
        <div className="network-error ion-padding ion-text-center">
          <IonIcon
            color={darkMode ? 'white' : 'danger'}
            className="cip-spinner"
            style={{ fontSize: '40px' }}
            icon={wifiOutline}
          />
          <h1 style={{ fontSize: '18px' }}>No Internet Connection</h1>
          <p>Please check your internet connection.</p>
        </div>
      )}
    </>
  );
};

export default connect({
  mapStateToProps: state => ({
    darkMode: state.user.darkMode,
  }),
  component: ArcGisMap,
});
