import React, { useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

export const initFirebase = () => {
  if (!isPlatform('capacitor')) {
    FirebaseAnalytics.initializeFirebase({
      apiKey: 'AIzaSyA_4GKqVhIf8FgohAGiAPDklTTsr2cr-6w',
      authDomain: 'tampa-cip.firebaseapp.com',
      // databaseURL: 'https://projects-plus-457ad-default-rtdb.firebaseio.com',
      projectId: 'tampa-cip',
      storageBucket: 'tampa-cip.appspot.com',
      messagingSenderId: '1034415374651',
      appId: '1:1034415374651:web:1b9d9fb82f6d736116f71e',
      measurementId: 'G-9ESZ0YWNXH',
    });
  }
};

export const setUserId = userId => {
  FirebaseAnalytics.setUserId({
    userId: userId,
  });
};

export const setUserProperty = property => {
  FirebaseAnalytics.setUserProperty({
    name: property.name,
    value: property.value,
    // name: "favorite_food",
    // value: "pizza",
  });
};

export const getAppInstanceId = async () => {
  const response = await FirebaseAnalytics.getAppInstanceId();
  const { instanceId } = response;
  // alert('Instance ID: ' + instanceId);
  return instanceId;
};

export const setScreenName = async screenName => {
  await FirebaseAnalytics.setScreenName({ screenName: screenName });
};

export const logEvent = async event => {
  FirebaseAnalytics.logEvent({
    name: event.name,
    params: event.params,
    // params: {
    //   content_type: 'image',
    //   content_id: 'P12453',
    //   items: [{ name: 'Kittens' }],
    // },
  });
};

export const Firebase = props => {
  let location = useLocation();
  useEffect(() => {
    FirebaseAnalytics.setScreenName({
      screenName: location.pathname,
      // nameOverride: location.pathname,
    });
  }, [location.pathname]);
  return <></>;
};
