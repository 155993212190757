import { combineReducers } from './combineReducers';
import { appReducer } from './app-data/app-data.reducer';
import { userReducer } from './user/user.reducer';

export const initialState = {
  data: {
    loading: false,
    networkStatus: true,
    projects: [],
    projectAttachments: [],
    projectCategories: [],
    filteredProjectCategories: [],
    projectPhases: [],
    filteredProjectPhases: [],
    alertFeed: { projectGroups: [] },
    alerts: [],
    alertCategories: [],
    filteredAlertCategories: [],
    projectSearchText: '',
    alertSearchText: '',
    showModal: { isOpen: false, page: '' },
    show2ndModal: { isOpen: false, page: '' },
  },
  user: {
    loading: false,
    hasSeenTutorial: false,
    themeSetting: 'light',
    darkMode: false,
    locationPermission: '',
    segmentPreference: 'all',
    favoritedProjects: [],
    subscribedProjects: [],
    // textSize: 'medium',
  },
};

export const reducers = combineReducers({
  data: appReducer,
  user: userReducer,
});
