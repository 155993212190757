/* eslint-disable no-dupe-keys */
import React from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import TimeSince from '../TimeSince';
import {
  faDoNotEnter,
  faTrafficCone,
  faLocationArrow,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faPresentation,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons';
library.add(
  faDoNotEnter,
  faTrafficCone,
  faLocationArrow,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faPresentation,
  faUserPlus,
);
//
// const iconMap = {
//   RoadClosures: 'do-not-enter',
//   PublicMeetings: 'users',
//   ConstructionNotices: 'traffic-cone',
// };
//
// const alertColorMap = {
//   RoadClosures: 'danger',
//   PublicMeetings: 'primary',
//   ConstructionNotices: 'inconstruction',
// };

const AlertListItem = ({ alert }) => {
  return (
    <IonCard
      className="project-card"
      routerLink={`/tabs/alerts/${alert.GlobalID}`}
    >
      <IonCardHeader>
        <div
          style={{
            display: '-webkit-flex',
            display: 'flex',
            WebkitJustifyContent: 'space-between',
            justifyContent: 'space-between',
            WebkitAlignItems: 'center',
            alignItems: 'center',
          }}
        >
          <IonCardTitle className="card-title">
            <FontAwesomeIcon
              className="alert-icon"
              color={`var(--ion-color-${alert.color})`}
              icon={['fas', `${alert.icon}`]}
              style={{ fontSize: '19px', minWidth: '24px' }}
            />
            &nbsp;
            {alert.title}
          </IonCardTitle>
        </div>
      </IonCardHeader>
      <IonCardContent className="alert-card">
        <p className="alert-description">
          {alert.subTitle && <>{alert.subTitle}&nbsp;&mdash;&nbsp;</>}
          {alert.description}
        </p>
        <div
          className="card-footer"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {alert.startDate && (
            <TimeSince date={alert.startDate} actionText="Issued" />
          )}
          <IonButton size="small" fill="clear" strong className="see-more">
            View Alert
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default React.memo(AlertListItem);
