import React from 'react';
import { useIonViewDidEnter } from '@ionic/react';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AlertListItem from '../../../components/AlertList/AlertListItem';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TimeSince from '../../../components/TimeSince';
// import { createParagraphs } from '../../../services/textFormatters';
import { connect } from '../../../data/connect';

const Alerts = ({ hide, alerts, projid }) => {
  useIonViewDidEnter(() => {
    FirebaseAnalytics.setScreenName({
      screenName: 'Project Alerts',
      nameOverride: 'Project Alerts',
    });
  });
  const projectAlerts = alerts.filter(function (a) {
    return a.attributes.projid === projid;
  });

  return (
    <div id="project-alerts-tab" style={hide ? { display: 'none' } : {}}>
      <div style={{ margin: '8px' }}></div>
      {/* <div className="line-divider"></div> */}
      {projectAlerts.length === 0 && (
        <>
          <div style={{ margin: '12px 16px 16px 16px' }}>
            <h4> Nothing Found</h4>
          </div>
          <div style={{ margin: '10px 16px 13px 16px' }}>
            <p>There aren't any alerts issued for this project</p>
          </div>
        </>
      )}

      {projectAlerts.length > 0 && (
        <>
          <div style={{ margin: '16px' }}></div>

          {projectAlerts.map(alert => (
            <AlertListItem key={alert.id} alert={alert.attributes} />
          ))}
          <div className="list-footer-space"></div>
        </>
      )}
    </div>
  );
};

export default connect({
  mapStateToProps: (state, OwnProps) => ({
    alerts: state.data.alerts,
  }),

  component: Alerts,
});
