import React, { useEffect, useState } from 'react';
import { IonIcon, IonItem, IonLabel, IonList, IonModal } from '@ionic/react';
import {
  helpCircleOutline,
  documentAttachOutline,
} from 'ionicons/icons';
import { setShowModal } from '../../../data/app-data/app-data.actions';
import { openBrowser } from '../../../services/browserApi';
import ProjectStatus from '../../../components/ProjectStatus';
import LightboxModal from '../../Modals/LightboxModal/LightboxModal';
import {
  createParagraphs,
  dateFormatter,
  dollarFormatter,
  updatedFormatter,
} from '../../../services/textFormatters';
import * as selectors from '../../../data/selectors';
import { connect } from '../../../data/connect';
import ImageThumbmails from '../../../components/ImageThumbails';

const titleMap = {
  'Art & Cultural Affairs': 'Art & Cultural Affairs',
  'Contract Administration': 'Contract Administration',
  'Convention Center & Tourism': 'Convention Center & Tourism',
  'Economic Opportunity': 'Economic Opportunity',
  'Facility Management': 'Facility Management',
  'Fire': 'Fire',
  'Fleet Maintenance': 'Fleet Maintenance',
  'HR-Risk Management': 'HR-Risk Management',
  'Parking': 'Parking',
  'Parks & Recreation': 'Parks & Recreation',
  'Planning': 'Planning',
  'Police': 'Police',
  'Solid Waste & EPM': 'Solid Waste & EPM',
  'Stormwater Engineering': 'Stormwater Engineering',
  'Technology & Innovation': 'Technology & Innovation',
  'Transportation': 'Transportation',
  'Wastewater': 'Wastewater',
  'Water': 'Potable Water',
  'Infrastructure': 'Infrastructure',
};

const Details = ({
  project,
  hide,
  setShowModal,
  projectAttachments,
  pageRef,
}) => {
  // console.log(project);
  const [images, setImages] = useState();

  // const [documents, setDocuments] = useState();
  useEffect(() => {
    // console.log(projectAttachments);
    const attachments = projectAttachments.find(
      a => a.parentObjectId === project.OBJECTID,
    );
    if (attachments) {
      const images = attachments.attachmentInfos.filter(a =>
        a.contentType.includes('image'),
      );
      // const documents = attachments.attachmentInfos.filter(a =>
      //   a.contentType.includes('pdf'),
      // );

      if (images.length >= 1) {
        setImages(images);
        // console.log(images);
      }
      // setDocuments(documents);
    }
  }, [projectAttachments, project]);
  // Images Lightbox
  const [showLightboxModal, setShowLightboxModal] = useState(false);
  const [lightboxContent, setLightboxContent] = useState();
  const [initialSlide, setInitialSlide] = useState(0);
  const openLightbox = (index, images) => {
    setLightboxContent(images);
    setInitialSlide(index);
    setShowLightboxModal(true);
  };

  return (
    <div id="project-information-tab" style={hide ? { display: 'none' } : {}}>
      <div style={{ margin: '8px' }}></div>
      {/* <div className="line-divider"></div> */}

      {project.projdesc && (
        <>
          <div style={{ margin: '12px 16px 16px 16px' }}>
            <h4>Description</h4>
          </div>
          <div style={{ margin: '10px 16px 13px 16px' }}>
            <p dangerouslySetInnerHTML={createParagraphs(project.projdesc)} />
          </div>
          <div className="line-divider"></div>
        </>
      )}

      {project.rationale && (
        <>
          <div style={{ margin: '12px 16px 16px 16px' }}>
            <h4 style={{ fontSize: '20px' }}>Rationale</h4>
          </div>
          <div style={{ margin: '13px 16px 16px 16px' }}>
            <p dangerouslySetInnerHTML={createParagraphs(project.rationale)} />
          </div>
          <div className="line-divider"></div>
        </>
      )}

      {images && (
        <>
          <div style={{ margin: '16px 16px 16px 16px' }}>
            <h4>Photos</h4>
          </div>
          <div className="block project-images">
            {images.map((image, index) => (
              <ImageThumbmails
                onClick={() => openLightbox(index, images)}
                src={image.url}
                key={index}
                alt="Image"
              />
            ))}
          </div>
          <IonModal
            id="lightboxModal"
            isOpen={showLightboxModal}
            onDidDismiss={() => setShowLightboxModal(false)}
            presentingElement={pageRef.current}
          >
            <LightboxModal
              onDismissModal={() => setShowLightboxModal(false)}
              initialSlide={initialSlide}
              images={lightboxContent}
              title="Current Activities"
            />
          </IonModal>
          <div className="line-divider"></div>
        </>
      )}

      <div style={{ margin: '16px 16px 10px' }}>
        <h4>Information</h4>
      </div>

      <IonList lines="inset" className="details-list">
      {project.docpath && (
          <IonItem
            detail
            onClick={() => openBrowser(project.docpath)}
            className="detail-item"
          >
            <IonLabel>
              <h3 style={{ color: 'var(--ion-color-primary-shade)' }}>
                Project Website
              </h3>
              <span style={{ fontSize: '12px' }}>{project.docpath}</span>
            </IonLabel>
            {/* <IonIcon
              color="primary"
              icon={globeOutline}
              slot="end"
              style={{ fontSize: '25px', minWidth: '25px' }}
            /> */}
          </IonItem>
        )}
        {project.projphase && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Current Phase</h3>
            </IonLabel>
            <ProjectStatus status={project.projphase} marginLeft="-1" />
          </IonItem>
        )}
        {project.projtype && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Category</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {titleMap[project.projtype]}
            </span>
          </IonItem>
        )}
        {project.planstart && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Start Date</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {dateFormatter(project.planstart)}
            </span>
          </IonItem>
        )}
        {project.planend && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>End Date</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {dateFormatter(project.planend)}
            </span>
          </IonItem>
        )}

        {project.fundsource && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Funding</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {project.fundsource}
            </span>
          </IonItem>
        )}
        {project.estcost && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Estimated Cost</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {dollarFormatter.format(project.estcost)}
            </span>
          </IonItem>
        )}
        {project.Neighborhood && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Neighborhood</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {project.Neighborhood}
            </span>
          </IonItem>
        )}
        {project.Council && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Council District</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {project.Council}
            </span>
          </IonItem>
        )}
        {project.fiscalyr && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Construction Fiscal Year</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {project.fiscalyr ? project.fiscalyr : 'N/A'}
            </span>
          </IonItem>
        )}
        <IonItem className="detail-item">
          <IonLabel>
            <h3>CRA</h3>
          </IonLabel>
          <span slot="end" style={{ fontSize: '16px' }}>
            {project.CRA ? project.CRA : 'Not in a CRA'}
          </span>
        </IonItem>
        {project.projid && (
          <IonItem className="detail-item">
            <IonLabel>
              <h3>Project ID</h3>
            </IonLabel>
            <span slot="end" style={{ fontSize: '16px' }}>
              {project.projid}
            </span>
          </IonItem>
        )}
        {project.ProjectSiteURL === 'none' ? (
          <></>
        ) : (
          <IonItem
            detail
            className="detail-item"
            onClick={() => openBrowser(project.ProjectSiteURL.split(/["]/)[1])}
          >
            <IonLabel>
              <h3
                style={{
                  color: 'var(--ion-color-primary-shade)',
                }}
              >
                Fact Sheet (PDF)
              </h3>
            </IonLabel>
            <IonIcon color="primary" icon={documentAttachOutline} slot="end" />
          </IonItem>
        )}
        {project.faqs && (
          <IonItem
            detail
            className="detail-item"
            onClick={() =>
              setShowModal({
                isOpen: true,
                page: 'project-faq',
                project: project,
              })
            }
          >
            <IonLabel>
              <h3 style={{ color: 'var(--ion-color-primary-shade)' }}>
                Frequently Asked Questions
              </h3>
            </IonLabel>
            <IonIcon color="primary" icon={helpCircleOutline} slot="end" />
          </IonItem>
        )}
      </IonList>
      <div className="information-list">
        Note: The cost and schedule data shown here are the City's current best
        estimates and are subject to change. Data is current as of the 15th of
        each month.
        <br />
        {project.EditDate && (
          <div
            style={{
              margin: '20px 0px 25px 0px',
              fontSize: '13px',
            }}
          >
            Last Updated: {updatedFormatter(project.EditDate)}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect({
  mapStateToProps: (state, OwnProps) => ({
    darkMode: state.user.darkMode,
    projectAttachments: selectors.getProjectAttachments(state),
  }),
  mapDispatchToProps: {
    setShowModal,
  },
  component: Details,
});
