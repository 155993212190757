/* eslint-disable default-case */
import {
  setFavoritedProjectsData,
  setSubscribedProjectsData,
} from '../dataApi';

export function userReducer(state, action) {
  switch (action.type) {
    case 'set-user-loading':
      return { ...state, loading: action.isLoading };
    case 'set-user-data':
      return { ...state, ...action.data };
    case 'set-has-seen-tutorial':
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case 'set-theme-setting':
      return { ...state, themeSetting: action.themeSetting };
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode };
    case 'set-location-permission': {
      return { ...state, locationPermission: action.locationPermission };
    }
    case 'set-segment-preference':
      return { ...state, segmentPreference: action.segmentPreference };
    case 'add-favorite': {
      setFavoritedProjectsData([...state.favoritedProjects, action.id]);
      return {
        ...state,
        favoritedProjects: [...state.favoritedProjects, action.id],
      };
    }
    case 'remove-favorite': {
      setFavoritedProjectsData([
        ...state.favoritedProjects.filter(x => x !== action.id),
      ]);
      return {
        ...state,
        favoritedProjects: [
          ...state.favoritedProjects.filter(x => x !== action.id),
        ],
      };
    }
    case 'add-subscription': {
      setSubscribedProjectsData([...state.subscribedProjects, action.id]);
      return {
        ...state,
        subscribedProjects: [...state.subscribedProjects, action.id],
      };
    }
    case 'remove-subscription': {
      setSubscribedProjectsData([
        ...state.subscribedProjects.filter(x => x !== action.id),
      ]);
      return {
        ...state,
        subscribedProjects: [
          ...state.subscribedProjects.filter(x => x !== action.id),
        ],
      };
    }
    case 'update-enabled-notifications': {
      return {
        ...state,
        enabledNotifications: action.enabledNotifications,
      };
    }
    // case 'set-text-size':
    // return { ...state, textSize: action.textSize };
    // case 'set-username':
    //   return { ...state, username: action.username };
    // case 'set-useremail':
    //   return { ...state, useremail: action.useremail };
  }
}
