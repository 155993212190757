// import { TextZoom } from '@capacitor/text-zoom';
import {
  getUserData,
  setHasSeenTutorialData,
  setDarkModeData,
  setSegmentPreferenceData,
  setEnabledNotificationsData,
  // setTextSizeData,
  // setUsernameData,
  // setUseremailData,
} from '../dataApi';

export const loadUserData = () => async dispatch => {
  dispatch(setLoading(true));
  const data = await getUserData();
  dispatch(setUserData(data));
  dispatch(setLoading(false));
};
export const setLoading = isLoading => ({
  type: 'set-user-loading',
  isLoading,
});
export const setUserData = data => ({
  type: 'set-user-data',
  data,
});
export const setHasSeenTutorial = hasSeenTutorial => async dispatch => {
  await setHasSeenTutorialData(hasSeenTutorial);
  return {
    type: 'set-has-seen-tutorial',
    hasSeenTutorial,
  };
};
export const setThemeSetting = themeSetting => async dispatch => {
  await setDarkModeData(themeSetting);
  return {
    type: 'set-theme-setting',
    themeSetting,
  };
};
export const setDarkMode = darkMode => async dispatch => {
  return {
    type: 'set-dark-mode',
    darkMode,
  };
};
export const setLocationPermission = locationPermission => ({
  type: 'set-location-permission',
  locationPermission,
});
export const setSegmentPreference = segmentPreference => async dispatch => {
  await setSegmentPreferenceData(segmentPreference);
  return {
    type: 'set-segment-preference',
    segmentPreference,
  };
};
export const addFavorite = id => ({
  type: 'add-favorite',
  id,
});
export const removeFavorite = id => ({
  type: 'remove-favorite',
  id,
});
export const addSubscription = id => ({
  type: 'add-subscription',
  id,
});
export const removeSubscription = id => ({
  type: 'remove-subscription',
  id,
});
export const updateEnabledNotifications =
  enabledNotifications => async dispatch => {
    await setEnabledNotificationsData(enabledNotifications);
    return {
      type: 'update-enabled-notifications',
      enabledNotifications,
    };
  };

// export const setTextSize = textSize => async dispatch => {
//   await setTextSizeData(textSize);
//   if (textSize === 'small') {
//     TextZoom.set({ value: 0.8 });
//   } else if (textSize === 'medium') {
//     TextZoom.set({ value: 1 });
//   } else if (textSize === 'large') {
//     TextZoom.set({ value: 1.2 });
//   } else if (textSize === 'xlarge') {
//     TextZoom.set({ value: 1.4 });
//   }
//   return {
//     type: 'set-text-size',
//     textSize,
//   };
// };
// export const setUsername = username => async dispatch => {
//   await setUsernameData(username);
//   return {
//     type: 'set-username',
//     username,
//   };
// };
// export const setUseremail = useremail => async dispatch => {
//   await setUseremailData(useremail);
//   return {
//     type: 'set-username',
//     useremail,
//   };
// };
